import React from "react";
import PropTypes from "prop-types";
import HTML from "../../Html";
import EzLayout from "../../../EzLayout";
import Card from "@cx/ui/Card";

const Html = ({ config, record }) => {
  let className =
    config.options && config.options.className
      ? `html-card ${config.options.className}`
      : "html-card";

  if (config.options.borderless) {
    className += " borderless";
  }

  return (
    <EzLayout.Section layout={config.layout}>
      <Card htmlId="can-you-believe-this-id" className={className}>
        <HTML.Templated config={config} record={record} />
      </Card>
    </EzLayout.Section>
  );
};

Html.propTypes = {
  config: PropTypes.object,
  record: PropTypes.object
};

export default Html;
