import { combineReducers } from "redux";
import sessionContext from "./authReducer";
import selectedVehicles from "./selectedVehiclesReducer";
import filters from "./filtersReducer";
import sorting from "./sortingReducer";
import paging from "./pagingReducer";
import vehicles from "./vehiclesReducer";
import viewConfig from "./viewConfigReducer";
import displayGroups from "./displayGroupReducer";
import loadingIndicator from "./loadingIndicatorReducer";
import queryStringParameters from "./queryStringParametersReducer";

const rootReducer = combineReducers({
  sessionContext,
  selectedVehicles,
  filters,
  sorting,
  paging,
  vehicles,
  viewConfig,
  displayGroups,
  loadingIndicator,
  queryStringParameters
});

export default rootReducer;
