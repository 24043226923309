import React from "react";
import PropTypes from "prop-types";
import Badge from "@cx/ui/Badge";
import Button from "@cx/ui/Button";
import IconClose from "@cx/ui/Icons/IconClose";

const Pill = ({ label, value, onClick, disabled }) => {
  return (
    <Badge className="pill" htmlId="pill">
      {label && <span>{label}: </span>}
      {value}
      <Button
        disabled={disabled}
        className="pill-close-button"
        htmlId="pill-close-button"
        onClick={onClick}
        bsStyle="link"
      >
        <IconClose htmlId="pill-close-icon" />
      </Button>
    </Badge>
  );
};

Pill.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string
};

export default Pill;
