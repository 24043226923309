import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzLayout from "../EzLayout";
import ezMapper from "../ezMapper";
import objectAssign from "object-assign";

const EzContainer = ({ componentData, displayGroups }) => {
  const { config } = componentData;
  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper
        className={config.options && config.options.className}
        layout={config.layout}
      >
        {config.components.map(component => {
          return ezMapper(
            displayGroups,
            objectAssign({}, componentData, { config: component })
          );
        })}
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

EzContainer.propTypes = {
  componentData: PropTypes.object.isRequired,
  displayGroups: PropTypes.array
};

function mapStateToProps(state) {
  return {
    isLoading: state.loadingIndicator.inventory,
    displayGroups: state.displayGroups
  };
}

export default connect(
  mapStateToProps,
  null
)(EzContainer);
