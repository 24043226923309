import {
  UPDATE_VIEW_CONFIG,
  INITIALIZE_QUERY_STRING_PARAMETERS
} from "./actionTypes";
import * as ezApi from "../api/ezApi";
import { initializeDisplayGroup } from "./displayGroupActions";
import { initializeInventory } from "./vehiclesActions";

export function getConfig(configurationId) {
  return function(dispatch) {
    ezApi.getConfig(configurationId).then(res => {
      if (res.data.success === true) {
        dispatch(initializeConfig(res.data.viewConfig));
      }
    });
  };
}

export function initializeConfig(viewConfig, queryStringParameters) {
  return function(dispatch) {
    dispatch({
      type: INITIALIZE_QUERY_STRING_PARAMETERS,
      queryStringParameters
    });
    if (viewConfig.configuration.defaultDisplayGroup) {
      dispatch(
        initializeDisplayGroup(viewConfig.configuration.defaultDisplayGroup)
      );
    }
    dispatch(updateViewConfig(viewConfig));
    dispatch(initializeData(viewConfig.configuration, queryStringParameters));
  };
}

function initializeData(configuration, querystringParameters) {
  return function(dispatch) {
    const initializeDataType =
      querystringParameters.initializeDataType ||
      configuration.initializeDataType;
    if (initializeDataType) {
      switch (initializeDataType) {
        case "inventory":
          return dispatch(
            initializeInventory(configuration, querystringParameters)
          );
        default:
          return null;
      }
    }
  };
}

export function updateViewConfig(viewConfig) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_VIEW_CONFIG,
      viewConfig
    });
  };
}
