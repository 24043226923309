import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import * as filtersActions from "../../../actions/filtersActions";
import keycodes from "keycodes";

const getFirstSelectedValue = filter =>
  (filter && filter.selectedValues && filter.selectedValues[0]) || "";

class SearchBox extends React.Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    filtersActions: PropTypes.object,
    options: PropTypes.object
  };

  state = {
    value: getFirstSelectedValue(this.props.filter)
  };

  onChange = cxEvent => this.setState({ value: cxEvent.target.value });

  updateSearch = value => {
    const { filtersActions, filter } = this.props;

    filtersActions.filterResults(
      filter.category,
      filter.type,
      (value && [value]) || [],
      null,
      null,
      true
    );
  };

  onBlur = cxEvent => {
    const { options, filter } = this.props;

    if (options === undefined || options.apply !== true) {
      const lastSearchedValue = getFirstSelectedValue(filter);

      if (cxEvent.target.value !== lastSearchedValue) {
        this.updateSearch(cxEvent.target.value);
      }
    }
  };

  onKeyHandler = (keyName, handler) => cxEvent => {
    if (cxEvent.keyCode === keycodes(keyName)) handler(cxEvent.target.value);
  };

  render() {
    const { options, filter } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <TextInput
          htmlId={"search-box"}
          displayLabel={false}
          label={""}
          placeholder={options.placeholder}
          name="searchBox"
          onChange={this.onChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyHandler("enter", this.updateSearch)}
          value={value}
        />
        {options && (options.apply === true || options.reset === true) && (
          <div className="filter-buttons">
            {options.reset === true && (
              <Button
                htmlId={`${filter.category}-reset-button`}
                onClick={() => this.updateSearch(null)}
              >
                Reset
              </Button>
            )}
            {options.apply === true && (
              <Button
                htmlId={`${filter.category}-apply-button`}
                onClick={() => this.updateSearch(this.state.value)}
                bsStyle="default"
                size="sm"
              >
                Search
              </Button>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(SearchBox);
