import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ezMapper from "../../../ezMapper";
import objectAssign from "object-assign";
import EzLayout from "../../../EzLayout";
import Card from "@cx/ui/Card";
import classNames from "classnames";

const Details = ({ componentData, displayGroups }) => {
  const { config } = componentData;

  const className = classNames(
    "details-card",
    { borderless: config.options && config.options.borderless === true },
    { offset: config.options && config.options.offset === true },
    { gray: config.options && config.options.background === "gray" }
  );

  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        <Card htmlId="container-card" className={className}>
          {config.options && config.options.title && (
            <h2 className="details-card-title">{config.options.title}</h2>
          )}
          <div className="details-card-body">
            {config.components.map(component => {
              return ezMapper(
                displayGroups,
                objectAssign({}, componentData, { config: component })
              );
            })}
          </div>
        </Card>
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

Details.propTypes = {
  componentData: PropTypes.object,
  displayGroups: PropTypes.array
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

export default connect(
  mapStateToProps,
  null
)(Details);
