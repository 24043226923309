import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

class Wrapper extends React.Component {
  // Does this really need to be a class???? no state.....
  constructor(props) {
    super(props);
    const { layout = {} } = props;

    /* eslint-disable */
    this.Grid = styled.div`
      ${(layout.gridRows || layout.gridColumns) &&
        `
      display: -ms-grid;
      display: grid;
      `}
      ${layout.gridRows &&
        layout.gridRows.length > 0 &&
        `
      -ms-grid-rows: ${layout.gridRows.join(" ")};
      grid-template-rows: ${layout.gridRows.join(" ")};
      `}
      ${layout.gridColumns &&
        layout.gridColumns.length > 0 &&
        `
      -ms-grid-columns: ${layout.gridColumns.join(" ")};
      grid-template-columns: ${layout.gridColumns.join(" ")};
      `}
    `;
    /* eslint-enable */
  }

  render() {
    const { children, ...other } = this.props;
    return <this.Grid {...other}>{children}</this.Grid>;
  }
}

Wrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func
  ]),
  layout: PropTypes.object
};

export default Wrapper;
