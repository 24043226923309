import { UPDATE_INVENTORY, INITIALIZE_INVENTORY } from "../actions/actionTypes";
import initialState from "./initialState";

export default function vehiclesReducer(state = initialState.vehicles, action) {
  switch (action.type) {
    case UPDATE_INVENTORY:
      return action.vehicles;
    case INITIALIZE_INVENTORY:
      return action.vehicles;
    default:
      return state;
  }
}
