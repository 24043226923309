import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import * as validation from "./validation";
import * as filtersActions from "../../../actions/filtersActions";
import { hasValue } from "../../../utils/common";

class MinMaxSelector extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    filter: PropTypes.object.isRequired,
    filtersActions: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedMin: this.getString(props.filter.selectedMin),
      selectedMax: this.getString(props.filter.selectedMax),
      minWarning: "",
      maxWarning: ""
    };
  }

  handleChange = (selectedMin, selectedMax) => {
    const { minWarning, maxWarning } = validation.validateMinMax(
      selectedMin,
      selectedMax
    );
    this.setState({
      minWarning,
      maxWarning,
      selectedMin,
      selectedMax
    });
  };

  updateSelectedMin = e => {
    this.handleChange(e.target.value, this.state.selectedMax);
  };

  updateSelectedMax = e => {
    this.handleChange(this.state.selectedMin, e.target.value);
  };

  onApplyClick = () => {
    this.props.filtersActions.filterResults(
      this.props.filter.category,
      "range",
      null,
      this.state.selectedMin,
      this.state.selectedMax
    );
  };

  onBlur = () => {
    const { config } = this.props;
    if (
      (config.options === undefined || config.options.apply !== true) &&
      this.isValid() &&
      this.hasChanged()
    ) {
      this.onApplyClick();
    }
  };

  onResetClick = () => {
    this.setState(
      {
        selectedMin: "",
        selectedMax: ""
      },
      () => {
        this.onApplyClick();
      }
    );
  };

  isValid = () => {
    const { maxWarning, minWarning } = this.state;
    return maxWarning === "" || minWarning === "";
  };

  hasChanged = () => {
    const { selectedMin, selectedMax } = this.state;
    const { filter } = this.props;
    return (
      selectedMin !==
        (hasValue(filter.selectedMin) ? filter.selectedMin.toString() : "") ||
      selectedMax !==
        (hasValue(filter.selectedMax) ? filter.selectedMax.toString() : "")
    );
  };

  getString = value => (hasValue(value) && value.toString()) || "";

  render() {
    const { filter, config } = this.props;
    const { selectedMax, selectedMin, minWarning, maxWarning } = this.state;
    const applyDisabled = this.isValid() && !this.hasChanged();
    return (
      <div>
        <div>
          <TextInput
            htmlId={`${filter.category}-min-box`}
            displayLabel={false}
            inputPrefix="Min"
            layout="vertical"
            name="min-box"
            error={minWarning}
            placeholder={filter.min}
            value={selectedMin}
            onChange={this.updateSelectedMin}
            onBlur={this.onBlur}
          />
          <TextInput
            htmlId={`${filter.category}-max-box`}
            displayLabel={false}
            inputPrefix="Max"
            layout="vertical"
            name="max-box"
            error={maxWarning}
            placeholder={filter.max}
            value={selectedMax}
            onChange={this.updateSelectedMax}
            onBlur={this.onBlur}
          />
        </div>
        <div className="filter-buttons">
          {config.reset === true && (
            <Button htmlId={"reset-button"} onClick={this.onResetClick}>
              Reset
            </Button>
          )}
          {config.apply === true && (
            <Button
              htmlId="apply-button"
              onClick={this.onApplyClick}
              disabled={applyDisabled}
              bsStyle="primary"
            >
              Apply
            </Button>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(MinMaxSelector);
