import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectButton from "../../SelectButton";
import Button from "@cx/ui/Button";
import * as displayGroupActions from "../../../../actions/displayGroupActions";
import * as selectedVehiclesActions from "../../../../actions/selectedVehiclesActions";
import EzLayout from "../../../EzLayout";

const Title = ({
  record,
  config,
  displayGroupActions,
  selectedVehiclesActions
}) => {
  const handleClick = () => {
    selectedVehiclesActions.updateSelectedVehicles([]);
    displayGroupActions.updateDisplayGroup(config.hide, config.show);
  };

  return (
    <EzLayout.Section layout={config.layout}>
      <h2 className="title-card-header">
        {record.description.year}
        {record.description.make}
        {record.description.model}
        {record.description.trim}
      </h2>
      {config.showSelectButton && (
        <SelectButton
          className="title-card-button"
          config={{ bsStyle: "link", text: "Select" }}
          index={0}
          record={record}
        />
      )}
      <Button
        className="title-card-button"
        htmlId="title-bar-cancel-button"
        onClick={handleClick}
      >
        Cancel
      </Button>
    </EzLayout.Section>
  );
};

Title.propTypes = {
  config: PropTypes.object,
  displayGroupActions: PropTypes.object,
  onCancelCallback: PropTypes.func,
  record: PropTypes.object,
  selectedVehiclesActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    displayGroupActions: bindActionCreators(displayGroupActions, dispatch),
    selectedVehiclesActions: bindActionCreators(
      selectedVehiclesActions,
      dispatch
    )
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Title);
