import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import * as message from "../../../utils/message";

const SelectButton = ({ options, record, index, ...other }) => {
  const handleClick = () => {
    // I don't really have another way to show postmessage at this time...
    /* eslint-disable */
    console.log(record);
    /* eslint-enable */
    message.sendMessage(message.MESSAGE_TYPE.VEHICLE_INFO, record);
  };

  return (
    <Button
      buttonStyle={options.bsStyle}
      htmlId={`select-button-${index}`}
      onClick={handleClick}
      {...other}
    >
      {options.text}
    </Button>
  );
};

SelectButton.propTypes = {
  index: PropTypes.number,
  options: PropTypes.object,
  record: PropTypes.object
};

export default SelectButton;
