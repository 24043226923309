import React from "react";
import PropTypes from "prop-types";
import nestedProperty from "nested-property";
import Html from "../Html";
import styled from "styled-components";

const DetailsList = ({ record, config }) => {
  /* eslint-disable */
  const Title = styled.h2`
    min-width: 0;
    min-height: 0;
    display: inline-block;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    font-weight: bold;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  `;

  const Divider = styled.hr`
    min-width: 0;
    min-height: 0;
    display: inline-block;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    font-weight: bold;
    -ms-grid-row: ${((config.items && config.items.length) || 0) + ((config.title && 1) || 0) + 2 };
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  `;

  const Wrapper = styled.div`
    display: -ms-inline-grid;
    display: inline-grid;
    -ms-grid-columns: auto 32px auto;
    grid-template-columns: auto 32px auto;
    -ms-grid-rows: auto (auto)[${(config.items && config.items.length) || 0}] auto;
  `;
  /* eslint-enable */

  const title = config.title ? <Title>{config.title}</Title> : null;

  const getContent = item => {
    if (item.content) {
      return item.content;
    } else if (nestedProperty.get(record, item.dataIndex)) {
      return {
        text: `{{${item.dataIndex}}}`,
        formatCurrency: `{{formatCurrency ${item.dataIndex}}}`,
        formatNumber: `{{formatNumber ${item.dataIndex}}}`,
        formatDateTime: `{{formatDateTime ${item.dataIndex} ${item.args}}}`,
        formatPercentage: `{{formatPercentage ${item.dataIndex}}}`
      }[item.type];
    } else {
      return item.altText || "";
    }
  };

  const detailItems = config.items.map((item, index) => {
    /* eslint-disable */
    const Label = styled.div`
      min-width: 0;
      min-height: 0;
      display: inline-block;
      -ms-grid-column: 1;
      grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      font-weight: bold;
      -ms-grid-row: ${index + 2};
      grid-row-start: ${index + 2};
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    `;

    const Content = styled.div`
      min-height: 0;
      min-width: 0;
      display: inline-block;
      -ms-grid-column: 3;
      grid-column-start: 3;
      -ms-grid-column-span: 1;
      grid-column-end: span 1;
      -ms-grid-row: ${index + 2};
      grid-row-start: ${index + 2};
      -ms-grid-row-span: 1;
      grid-row-end: span 1;
    `;
    /* eslint-enable */

    return (
      <>
        <Label>{item.label}: </Label>
        <Content>
          <Html.Templated
            config={{ content: getContent(item) }}
            record={record}
          />
        </Content>
      </>
    );
  });

  const divider = config.showDivider === true ? <Divider /> : null;
  return (
    <Wrapper>
      {title}
      {detailItems}
      {divider}
    </Wrapper>
  );
};

DetailsList.propTypes = {
  config: PropTypes.object,
  record: PropTypes.object
};

export default DetailsList;
