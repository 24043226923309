import React from "react";
import PropTypes from "prop-types";
import Table from "@cx/ui/Table";
import mapColumns from "./ezGridMapper";
import objectAssign from "object-assign";
import ezMapper from "../ezMapper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as pagingActions from "../../actions/pagingActions";
import * as selectedVehiclesActions from "../../actions/selectedVehiclesActions";
import * as vehicleActions from "../../actions/vehiclesActions";
import EzLayout from "../EzLayout";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const EzGrid = ({
  config,
  displayGroups,
  isLoading,
  paging,
  selectedVehicles,
  selectedVehiclesActions,
  vehicles
}) => {
  const onRowClick = (record, index) => {
    let selectedRecords = [];
    if (highlightedRows.indexOf(index) === -1) {
      selectedRecords =
        config.options && config.options.rowAction === "multiSelect"
          ? selectedVehicles.concat(objectAssign({}, record, { index }))
          : [objectAssign({}, record, { index })];
    } else {
      selectedRecords = selectedVehicles.filter(x => x.index !== index);
    }
    selectedVehiclesActions.updateSelectedVehicles(selectedRecords);
  };

  const highlightedRows = selectedVehicles.map(x => x.index);

  const rowClickEnabled =
    config.options &&
    ["select", "multiSelect"].indexOf(config.options.rowAction || -1) > -1;

  return (
    <EzLayout.Section layout={config.layout}>
      <div>
        {isLoading === true ? (
          <LoadingIndicator htmlId="cx-cannot-figure-out-how-to-manage-state" />
        ) : (
          <Table
            columns={mapColumns(config.columns, displayGroups)}
            data={vehicles.map((vehicle, index) => {
              return objectAssign({}, vehicle, { key: index });
            })}
            dataDensity={config.options && config.options.dataDensity}
            htmlId="Ez-Vu-Grid"
            pageSize={paging.pageSize}
            enablePagination={false}
            displayPageSizeSelector={false}
            sortableColumns={false}
            expandRowOnClick={
              config.expandedRowComponent !== undefined &&
              config.options &&
              config.options.rowAction === "expand"
            }
            expandedRowComponent={
              // disabling a linting rule that should really only apply to stateful components. its dumb
              /* eslint-disable */
              config.expandedRowComponent !== undefined
                ? record => (
                    <EzLayout.Wrapper
                      layout={config.expandedRowComponent.layout}
                    >
                      {config.expandedRowComponent.components.map(component => {
                        return ezMapper(displayGroups, {
                          config: component,
                          record
                        });
                      })}
                    </EzLayout.Wrapper>
                  )
                : undefined
              /* eslint-enable */
            }
            emptyText={"No Data Available"}
            isLoading={isLoading}
            onRow={
              rowClickEnabled
                ? (record, index) => ({
                    onClick: event => onRowClick(record, index)
                  })
                : undefined
            }
            rowClassName={(record, index) =>
              highlightedRows.indexOf(index) > -1
                ? "selected-row table-row"
                : "table-row"
            }
            scrollY={config.options && config.options.scrollY}
            scrollX={config.options && config.options.scrollX}
          />
        )}
      </div>
    </EzLayout.Section>
  );
};

EzGrid.propTypes = {
  config: PropTypes.object.isRequired,
  displayGroups: PropTypes.array,
  isLoading: PropTypes.bool,
  paging: PropTypes.object,
  pagingActions: PropTypes.object,
  selectedVehicles: PropTypes.array,
  selectedVehiclesActions: PropTypes.object,
  vehicleActions: PropTypes.object,
  vehicles: PropTypes.array
};

function mapStateToProps(state) {
  return {
    selectedVehicles: state.selectedVehicles,
    vehicles: state.vehicles,
    paging: state.paging,
    isLoading: state.loadingIndicator.inventory,
    displayGroups: state.displayGroups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectedVehiclesActions: bindActionCreators(
      selectedVehiclesActions,
      dispatch
    ),
    pagingActions: bindActionCreators(pagingActions, dispatch),
    vehicleActions: bindActionCreators(vehicleActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EzGrid);
