import { AUTHENTICATION_FAILURE, AUTHENTICATION_SUCCESS } from "./actionTypes";
import { initializeSession } from "../api/ezApi";
import { initializeConfig } from "./viewConfigActions";
import objectAssign from "object-assign";

export function authenticate(querystringParameters) {
  let iterator = 0;
  const assignIds = node => {
    if (typeof node === "object") {
      if (Array.isArray(node)) {
        node.forEach(assignIds);
      } else {
        node.id = ++iterator;
        Object.keys(node).forEach(key => assignIds(node[key]));
      }
    }
  };
  return function(dispatch) {
    if (querystringParameters.authKey) {
      const handleAuthenticationFailure = response => {
        const errorMessages = (response &&
          response.data &&
          response.data.error_messages) || ["Unknown error"];

        dispatch({
          type: AUTHENTICATION_FAILURE,
          sessionContext: {
            isLoading: false,
            authenticated: false,
            errorMessage: errorMessages,
            sessionToken: "",
            sources: []
          }
        });
      };

      initializeSession(querystringParameters.authKey).then(
        res => {
          if (res.data.success === true) {
            dispatch({
              type: AUTHENTICATION_SUCCESS,
              sessionContext: objectAssign({}, res.data.sessionContext, {
                isLoading: false,
                authenticated: true
              })
            });
            // If no viewConfig provided in auth response,
            // then use a default config.
            // TODO: Remove this default behavior after
            // we have achieved a mature production state;
            // this is a feature only for rapid development.
            const viewConfig = res.data.viewConfig || {
              configId: "defaultConfig",
              configuration: require("../config.json")
            };
            assignIds(viewConfig.configuration);
            dispatch(initializeConfig(viewConfig, querystringParameters));
          } else {
            handleAuthenticationFailure(res);
          }
        },
        ({ response }) => handleAuthenticationFailure(response)
      );
    } else {
      dispatch({
        type: AUTHENTICATION_FAILURE,
        sessionContext: {
          isLoading: false,
          authenticated: false,
          errorMessage: ["No authKey was provided."],
          sessionToken: "",
          sources: []
        }
      });
    }
  };
}
