import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import objectAssign from "object-assign";
import EzLayout from "../../../EzLayout";
import Html from "../../Html";

const PageCount = ({ config, vehicles, paging }) => {
  const record = objectAssign({}, paging, {
    pageSize: (vehicles || []).length
  });
  const content =
    vehicles.length > 0 ? config.content : config.noRecordsContent || "";

  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        <Html.Templated
          config={objectAssign({}, config, { content })}
          record={record}
        />
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

PageCount.propTypes = {
  config: PropTypes.object,
  paging: PropTypes.object,
  vehicles: PropTypes.array
};

function mapStateToProps(state) {
  return {
    paging: state.paging,
    vehicles: state.vehicles
  };
}

export default connect(
  mapStateToProps,
  null
)(PageCount);
