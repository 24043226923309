import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@cx/ui/Button";
import * as selectedVehiclesActions from "../../../actions/selectedVehiclesActions";

import objectAssign from "object-assign";

export const DetailsButton = ({
  options,
  index,
  record,
  selectedVehiclesActions
}) => {
  const handleClick = () => {
    selectedVehiclesActions.updateSelectedVehicles([
      objectAssign({}, record, { index })
    ]);
  };

  return (
    <Button
      htmlId={`toggle-button-${index}`}
      onClick={handleClick}
      bsStyle={options && options.bsStyle}
    >
      {(options && options.text) || "Details"}
    </Button>
  );
};

DetailsButton.propTypes = {
  index: PropTypes.number,
  options: PropTypes.object,
  record: PropTypes.object,
  selectedVehiclesActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    selectedVehiclesActions: bindActionCreators(
      selectedVehiclesActions,
      dispatch
    )
  };
}

export default connect(
  null,
  mapDispatchToProps
)(DetailsButton);
