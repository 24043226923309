import React from "react";
import EzGrid from "./EzGrid";
import EzEquipment from "./EzEquipment";
import EzFilter from "./EzFilter";
import EzPill from "./EzPill";
import EzHtml from "./EzHtml";
import EzDetails from "./EzDetails";
import EzContainer from "./EzContainer";
import EzMounter from "./EzMounter";
import EzSlidingDrawer from "./EzSlidingDrawer";
import EzFullPageModal from "./EzFullPageModal";
import Html from "./reusable/Html";
import Cards from "./reusable/Cards";
import Thumbnail from "./reusable/Thumbnail";
import DetailsButton from "./reusable/DetailsButton";
import CancelButton from "./reusable/CancelButton";
import WebLink from "./reusable/WebLink";
import SelectButton from "./reusable/SelectButton";
import DetailsList from "./reusable/DetailsList";
import ImageCarousel from "./reusable/ImageCarousel";
import Button from "@cx/ui/Button";
import Buttons from "./reusable/Buttons";
import Icon from "./reusable/Icon";
import Tooltip from "./reusable/Tooltip";
import * as formatters from "../utils/formatters";
import Paging from "./reusable/Paging";
import LoadMore from "./reusable/Paging/LoadMore";
import EzConditional from "./EzConditional";

export default function ezMapper(displayGroups, componentData) {
  if (
    displayGroups.length === 0 ||
    componentData.config.displayGroup === undefined ||
    displayGroups.indexOf(componentData.config.displayGroup) !== -1
  ) {
    return componentDictionary[componentData.config.type](componentData);
  }
}

/* eslint-disable */
const componentDictionary = {
  ezGrid: ({ config }) => <EzGrid key={config.id} config={config} />,
  ezEquipment: ({ config, record }) => (
    <EzEquipment key={config.id} config={config} record={record} />
  ),
  ezFilter: ({ config }) => <EzFilter key={config.id} config={config} />,
  ezPill: ({ config }) => <EzPill key={config.id} config={config} />,
  ezHtml: ({ config }) => <EzHtml key={config.id} config={config} />,
  ezDetails: ({ config }) => <EzDetails key={config.id} config={config} />,
  ezContainer: componentData => (
    <EzContainer key={componentData.config.id} componentData={componentData} />
  ),
  ezMounter: componentData => (
    <EzMounter key={componentData.config.id} componentData={componentData} />
  ),
  ezSlidingDrawer: componentData => (
    <EzSlidingDrawer
      key={componentData.config.id}
      componentData={componentData}
    />
  ),
  ezFullPageModal: componentData => (
    <EzFullPageModal
      key={componentData.config.id}
      componentData={componentData}
    />
  ),
  staticHtml: ({ config }) => <Html.Static key={config.id} config={config} />,
  imageCarousel: ({ config }) => (
    <ImageCarousel key={config.id} config={config} />
  ),
  detailsList: ({ config, record }) => (
    <DetailsList key={config.id} config={config} record={record} />
  ),
  titleCard: ({ config, record }) => (
    <Cards.Title key={config.id} config={config} record={record} />
  ),
  imageCard: ({ config, record }) => (
    <Cards.Image key={config.id} config={config} record={record} />
  ),
  detailsCard: componentData => (
    <Cards.Details
      key={componentData.config.id}
      componentData={componentData}
    />
  ),
  htmlCard: ({ config, record }) => (
    <Cards.Html key={config.id} config={config} record={record} />
  ),
  thumbnail: ({ config, record }) => (
    <Thumbnail key={config.id} options={config.options} record={record} />
  ),
  templatedHtml: ({ config, record }) => (
    <Html.Templated key={config.id} config={config} record={record} />
  ),
  webLink: ({ config, record }) => (
    <WebLink key={config.id} options={config.options} record={record} />
  ),
  currency: ({ config, text }) => (
    <span className="currency" key={config.id}>{formatters.formatCurrencyDefaultBlank(text)}</span>
  ),
  dateTime: ({ config, text }) => (
    <DateTimeFormat key={config.id} options={config.options} text={text} />
  ),
  number: ({ config, text }) => (
    <span key={config.id}>{formatters.formatNumberDefaultBlank(text)}</span>
  ),
  percentage: ({ config, text }) => (
    <span key={config.id}>{formatters.formatPercentage(text)}</span>
  ),
  text: ({ config, text }) => <span key={config.id}>{text}</span>,
  selectButton: ({ config, record, index }) => (
    <SelectButton
      key={config.id}
      options={config.options}
      record={record}
      index={index}
    />
  ),
  detailsButton: ({ config, record, index }) => (
    <DetailsButton
      key={config.id}
      options={config.options}
      record={record}
      index={index}
    />
  ),
  cancelButton: (componentData) => (
    <CancelButton key={componentData.config.id} componentData={componentData} index={componentData.index} />
  ),
  button: ({ config }) => (
    <Button
      key={config.id}
      htmlId="config-button"
      bsStyle={config.options && config.options.bsStyle}
      size={config.options && config.options.size}
      className={config.className}
    >
      {config.options && config.options.text}
    </Button>
  ),
  icon: ({ config }) => <Icon key={config.id} config={config} />,
  tooltip: componentData => (
    <Tooltip key={componentData.config.id} componentData={componentData} />
  ),
  exportCSV: componentData => (
    <Buttons.ExportCSV
      key={componentData.config.id}
      componentData={componentData}
    />
  ),
  pageCount: ({ config }) => (
    <Paging.PageCount key={config.id} config={config} />
  ),
  loadMore: (componentData) => (
    <LoadMore key={componentData.config.id} componentData={componentData} />
  ),
  ezConditional: (componentData) => (
    <EzConditional key={componentData.config.id} componentData={componentData} />
  )
};
/* eslint-enable */
