export default {
  displayGroups: [],
  selectedVehicles: [],
  sessionContext: {
    authenticated: false,
    isLoading: true,
    sessionToken: "",
    sources: []
  },
  vehicles: [],
  filters: [],
  paging: {},
  sorting: [],
  viewConfig: {
    configId: "",
    configuration: {}
  },
  loadingIndicator: {
    inventory: true,
    filters: true
  },
  queryStringParameters: {}
};
