import {
  UPDATE_LOADING_INDICATOR,
  TOGGLE_COLLAPSIBLE_FILTER,
  UPDATE_FILTERS,
  UPDATE_INVENTORY,
  UPDATE_PAGING,
  UPDATE_SELECTED_VEHICLES,
  UPDATE_SORTING
} from "./actionTypes";
import * as ezApi from "../api/ezApi";
import { hasValue } from "../utils/common";
import objectAssign from "object-assign";

export function filterResults(
  category,
  type,
  selectedValues,
  selectedMin,
  selectedMax,
  clearSorting = false
) {
  return function(dispatch, getState) {
    const {
      filters,
      paging,
      sessionContext,
      sorting,
      loadingIndicator
    } = getState();
    const request = {
      filters: buildFilterRequest(
        filters.map(filter => {
          if (filter.category === category) {
            return objectAssign({}, filter, {
              type,
              selectedValues,
              selectedMin,
              selectedMax
            });
          }
          return filter;
        })
      ),
      paging,
      sorting: clearSorting === true ? [] : sorting,
      source: sessionContext.sources[0]
    };
    dispatch({
      type: UPDATE_LOADING_INDICATOR,
      loadingIndicator: objectAssign({}, loadingIndicator, {
        inventory: true,
        filters: true
      })
    });
    ezApi.getInventory(request, sessionContext.sessionToken).then(res => {
      /* TODO: find way to improve filters efficiency. Server side config?
       Mapping it here would save processing power every render,
       however, mapping it in the component allows us to have more options
       for filtering, like a separate component to render in a different use case
       */
      if (res.data.success === true) {
        dispatch(updateFilters(res.data.filters));
        dispatch({
          type: UPDATE_SELECTED_VEHICLES,
          selectedVehicles: []
        });
        dispatch({
          type: UPDATE_INVENTORY,
          vehicles: res.data.vehicles
        });
        dispatch({
          type: UPDATE_PAGING,
          paging: res.data.paging
        });
        dispatch({
          type: UPDATE_LOADING_INDICATOR,
          loadingIndicator: objectAssign({}, getState().loadingIndicator, {
            inventory: false,
            filters: false
          })
        });
        if (clearSorting) {
          dispatch({
            type: UPDATE_SORTING,
            sorting: []
          });
        }
      }
      // TODO: add error handling here
    });
  };
}

export function updateSelectedValues(category, type, selectedValues) {
  return function(dispatch, getState) {
    const { filters } = getState();
    dispatch({
      type: UPDATE_FILTERS,
      filters: filters.map(filter => {
        if (filter.category === category) {
          return objectAssign({}, filter, {
            type,
            selectedValues
          });
        }
        return filter;
      })
    });
  };
}

export function toggleCollapsibleFilter(category, expanded) {
  return function(dispatch, getState) {
    const { filters } = getState();
    dispatch({
      type: TOGGLE_COLLAPSIBLE_FILTER,
      filters: filters.map(filter => {
        if (filter.category === category) {
          return objectAssign({}, filter, { options: { expanded } });
        }
        return filter;
      })
    });
  };
}

export function updateFilters(res) {
  return function(dispatch, getState) {
    const { filters } = getState();
    const responseFilters = mapFilterResponse(filters, res);
    // Only update the filters if there is a change. kind of nasty actually
    if (JSON.stringify(filters) !== responseFilters) {
      dispatch({
        type: UPDATE_FILTERS,
        filters: responseFilters
      });
    }
  };
}

export function buildFilterRequest(filters) {
  return filters.map(filter => {
    const newFilter = {
      category: filter.category,
      type: filter.type
    };
    if (filter.selectedValues && filter.selectedValues.length > 0)
      newFilter.selectedValues = filter.selectedValues;
    if (hasValue(filter.selectedMin) && filter.selectedMin >= 0)
      newFilter.selectedMin = filter.selectedMin;
    if (hasValue(filter.selectedMax) && filter.selectedMax >= 0)
      newFilter.selectedMax = filter.selectedMax;
    return newFilter;
  });
}

function mapFilterResponse(filters, responseFilters) {
  return responseFilters.map(res => {
    const filter = filters.find(x => x.category === res.category);
    if (filter !== undefined) {
      return objectAssign({}, res, {
        options: filter.options
      });
    }
    return res;
  });
}

export function buildQueryStringFilters(configFilters, queryStringParameters) {
  let isDeeplink = false;
  const filters = configFilters.map(x => {
    let selectedValues = [];
    if (queryStringParameters[x.category] !== undefined) {
      isDeeplink = true;
      selectedValues = queryStringParameters[x.category].split(",");
    }
    return objectAssign({}, x, { selectedValues });
  });
  return { filters, isDeeplink };
}

export function clearFilters() {
  return function(dispatch, getState) {
    const {
      paging,
      sorting,
      sessionContext,
      loadingIndicator,
      filters
    } = getState();
    const request = {
      filters: filters.map(filter => {
        return objectAssign({}, filter, {
          selectedValues: null,
          selectedMax: null,
          selectedMin: null
        });
      }),
      paging,
      sorting,
      source: sessionContext.sources[0]
    };
    dispatch({
      type: UPDATE_LOADING_INDICATOR,
      loadingIndicator: objectAssign({}, loadingIndicator, {
        inventory: true,
        filters: true
      })
    });
    ezApi.getInventory(request, sessionContext.sessionToken).then(res => {
      /* TODO: find way to improve filters efficiency. Server side config?
       Mapping it here would save processing power every render,
       however, mapping it in the component allows us to have more options
       for filtering, like a separate component to render in a different use case
       */
      if (res.data.success === true) {
        dispatch(updateFilters(res.data.filters));
        dispatch({
          type: UPDATE_SELECTED_VEHICLES,
          selectedVehicles: []
        });
        dispatch({
          type: UPDATE_INVENTORY,
          vehicles: res.data.vehicles
        });
        dispatch({
          type: UPDATE_PAGING,
          paging: res.data.paging
        });
        dispatch({
          type: UPDATE_LOADING_INDICATOR,
          loadingIndicator: objectAssign({}, getState().loadingIndicator, {
            inventory: false,
            filters: false
          })
        });
      }
      // TODO: add error handling here
    });
  };
}
