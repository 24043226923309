import React from "react";
import PropTypes from "prop-types";
import IconKeyboardArrowUp from "@cx/ui/Icons/IconKeyboardArrowUp";
import IconKeyboardArrowDown from "@cx/ui/Icons/IconKeyboardArrowDown";
import Button from "@cx/ui/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as sortingActions from "../../../actions/sortingActions";
import * as loadingIndicatorActions from "../../../actions/loadingIndicatorActions";
import objectAssign from "object-assign";

const ColumnSort = ({
  title,
  name,
  sortingActions,
  loadingIndicator,
  loadingIndicatorActions
}) => {
  const sortAscending = () => {
    sortingActions.sortVehicles(name, false);
  };

  const sortDescending = () => {
    loadingIndicatorActions.updateLoadingIndicator(
      objectAssign({}, loadingIndicator, { inventory: true })
    );
    sortingActions.sortVehicles(name, true);
  };

  return (
    <div className="column-sort">
      <span className="column-sort-title">{title || ""}</span>
      <div className="column-sorter">
        <Button
          htmlId={name + "-KeyboardArrowUp-Button"}
          bsStyle="link"
          onClick={sortAscending}
          className="vehicle-views-column-sorter-up"
        >
          <IconKeyboardArrowUp htmlId={name + "-KeyboardArrowDown"} />
        </Button>
        <Button
          htmlId={name + "-KeyboardArrowDown-Button"}
          bsStyle="link"
          onClick={sortDescending}
          className="vehicle-views-column-sorter-down"
        >
          <IconKeyboardArrowDown htmlId={name + "-KeyboardArrowDown"} />
        </Button>
      </div>
    </div>
  );
};

ColumnSort.propTypes = {
  loadingIndicator: PropTypes.object,
  loadingIndicatorActions: PropTypes.object,
  name: PropTypes.string,
  sortingActions: PropTypes.object.isRequired,
  title: PropTypes.string
};

function mapDispatchToProps(dispatch) {
  return {
    sortingActions: bindActionCreators(sortingActions, dispatch),
    loadingIndicatorActions: bindActionCreators(
      loadingIndicatorActions,
      dispatch
    )
  };
}

function mapStateToProps(state) {
  return {
    loadingIndicator: state.loadingIndicator
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumnSort);
