import React from "react";
import PropTypes from "prop-types";
import Carousel from "react-bootstrap/lib/Carousel";
import IconKeyboardArrowLeft from "@cx/ui/Icons/IconKeyboardArrowLeft";
import IconKeyboardArrowRight from "@cx/ui/Icons/IconKeyboardArrowRight";
import nestedProperty from "nested-property";

const emptyArray = [];

const ImageCarousel = ({
  images = [],
  record,
  dataIndex = "inventoryDetail.sourceVehiclePhotoUrls"
}) => {
  const vehicleImage = nestedProperty.get(record, dataIndex) || emptyArray;
  const carouselImages = images.length > 0 ? images : vehicleImage;
  return (
    <Carousel
      prevIcon={<IconKeyboardArrowLeft className="glyphicon-chevron-left " />}
      nextIcon={<IconKeyboardArrowRight className="glyphicon-chevron-right " />}
    >
      {carouselImages.map(image => {
        return (
          <Carousel.Item key={image}>
            <img alt="primary" id={"img-" + image} width="100%" src={image} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

ImageCarousel.propTypes = {
  dataIndex: PropTypes.string,
  images: PropTypes.array,
  record: PropTypes.object
};

export default ImageCarousel;
