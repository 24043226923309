import axios from "axios";

const devMode = RegExp("^local.*|127.0.0.1$", "i").test(
  window.location.hostname
);
const apiDomain = getApiDomain();
const axiosInstance = function() {
  // if (api) return api; // return initialized api if already initialized.
  return axios.create({
    baseURL: devMode
      ? "https://dev-vehiclesearch.vinsolutions.com/api"
      : apiDomain,
    responseType: "json",
    withCredentials: false,
    headers: { "x-api-version": "3.0" }
  });
};

export const get = function(url, config) {
  return axiosInstance().get(url, config);
};

export const post = function(url, data, config) {
  return axiosInstance().post(url, data, config);
};

export function getConfig(id) {
  return get(`/configs/${id}`);
}

export function initializeSession(authToken) {
  return get(`auth/${authToken}`);
}

export function getInventory(request, sessionKey) {
  // return Promise.resolve({ data: require("./mockVehiclesInventory.json") });
  return post(`vehicles/inventory`, request, {
    headers: { sessionKey, "x-api-version": "3.0" }
  });
}

export function exportInventory(request, sessionKey) {
  return post(`vehicles/export`, request, {
    headers: { sessionKey, "x-api-version": "3.0" },
    responseType: "blob"
  });
}

function getApiDomain() {
  if (window.location.hostname === "dev-vehicle-views.vinsolutions.com") {
    return `${
      window.location.protocol
    }//dev-vehiclesearch.vinsolutions.com/api`;
  } else if (window.location.hostname === "qa-vehicle-views.vinsolutions.com") {
    return `${window.location.protocol}//qa-vehiclesearch.vinsolutions.com/api`;
  } else if (
    window.location.hostname === "staging-vehicle-views.vinsolutions.com"
  ) {
    return `${
      window.location.protocol
    }//staging-vehiclesearch.vinsolutions.com/api`;
  } else if (window.location.hostname === "vehicle-views.vinsolutions.com") {
    return `${window.location.protocol}//vehiclesearch.vinsolutions.com/api`;
  } else {
    return "";
  }
}
