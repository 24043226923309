import {
  FILTER_INVENTORY,
  UPDATE_FILTERS,
  INITIALIZE_FILTERS,
  TOGGLE_COLLAPSIBLE_FILTER
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function filtersReducer(state = initialState.filters, action) {
  switch (action.type) {
    case FILTER_INVENTORY:
      return action.filters;
    case UPDATE_FILTERS:
      return action.filters;
    case INITIALIZE_FILTERS:
      return action.filters;
    case TOGGLE_COLLAPSIBLE_FILTER:
      return action.filters;
    default:
      return state;
  }
}
