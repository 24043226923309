import React from "react";
import PropTypes from "prop-types";
import nestedProperty from "nested-property";
import ModalDialog from "@cx/ui/ModalDialog";
import * as validation from "./validation";
import * as common from "../../../utils/common";
import Button from "@cx/ui/Button";

class WebLink extends React.Component {
  static propTypes = {
    options: PropTypes.object.isRequired,
    record: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.text = this.props.options.linkText || this.props.options.href;
    this.confirmMsg =
      this.props.options.confirmText ||
      "Are you sure you wish to navigate away from this page?";
    this.state = {
      showModal: false
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  navigate = () => {
    const { options, record } = this.props;
    let url = validation.verifyUrl(options.href);
    if (common.hasValue(options.parameters) && options.parameters.length > 0) {
      const parameters = [];
      options.parameters.forEach(function(parameter) {
        // type var suggests that a value within the record is needed {variable} and a lookup for that value must be performed first
        parameter.type === "var"
          ? parameters.push({
              name: parameter.name,
              value: nestedProperty.get(record, parameter.value) || ""
            })
          : parameters.push(parameter);
      });
      url = `${url}?${parameters.map(p => `${p.name}=${p.value}`).join("&")}`;
    }
    this.toggleModal();
    if (options.navToNewPage) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  handleClick = () => {
    if (this.props.options.confirmBeforeNav) {
      this.toggleModal();
    } else {
      this.navigate();
    }
  };

  render() {
    return (
      <div>
        <Button
          htmlId={`weblink-${this.props.options.href}`}
          onClick={this.handleClick}
        >
          {this.text}
        </Button>
        <ModalDialog
          htmlId="NavigateAwayConfirmation"
          className="nav-away-confirmaiton-box"
          show={this.state.showModal}
          onHide={this.toggleModal}
          header={this.confirmMsg}
        >
          <Button
            className="modal-button"
            htmlId="ConfirmNav"
            onClick={this.navigate}
          >
            Yes
          </Button>
          <Button
            className="modal-button"
            htmlId="DenyNav"
            onClick={this.toggleModal}
          >
            No
          </Button>
        </ModalDialog>
      </div>
    );
  }
}

export default WebLink;
