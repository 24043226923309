import {
  INITIALIZE_INVENTORY,
  INITIALIZE_FILTERS,
  INITIALIZE_SORTING,
  INITIALIZE_PAGING,
  UPDATE_LOADING_INDICATOR
} from "./actionTypes";
import * as ezApi from "../api/ezApi";
import objectAssign from "object-assign";
import { buildFilterRequest, buildQueryStringFilters } from "./filtersActions";
import { updateSelectedVehicles } from "./selectedVehiclesActions";
import { initializeDisplayGroup } from "./displayGroupActions";
import FileSaver from "file-saver";
import { formatDateTime } from "../utils/formatters";

export function initializeInventory(configuration, queryStringParameters) {
  return function(dispatch, getState) {
    const { sessionContext, loadingIndicator } = getState();
    const filterInfo = buildQueryStringFilters(
      buildFilterRequest(configuration.settings.filters),
      queryStringParameters
    );
    const request = {
      filters: filterInfo.filters,
      paging: configuration.settings.paging || { pageNumber: 1, pageSize: 10 },
      sorting: configuration.settings.sorting || [],
      source: sessionContext.sources[0]
    };
    dispatch({
      type: UPDATE_LOADING_INDICATOR,
      loadingIndicator: objectAssign({}, loadingIndicator, {
        inventory: true,
        filters: true
      })
    });
    ezApi.getInventory(request, sessionContext.sessionToken).then(res => {
      /* TODO: find way to improve filters efficiency. Server side config?
       Mapping it here would save processing power every render,
       however, mapping it in the component allows us to have more options
       for filtering, like a separate component to render in a different use case
       */
      if (res.data.success === true) {
        if (filterInfo.isDeeplink && configuration.deeplink) {
          if (res.data.vehicles.length === 1) {
            if (configuration.deeplink.selectSingleRecord === true) {
              dispatch(
                updateSelectedVehicles([
                  objectAssign({}, res.data.vehicles[0], { index: 0 })
                ])
              );
            }
            if (configuration.deeplink.singleResultDisplayGroups) {
              dispatch(
                initializeDisplayGroup(
                  configuration.deeplink.singleResultDisplayGroups
                )
              );
            }
          } else if (
            configuration.deeplink.multipleResultsDislayGroups &&
            res.data.vehicles.length > 1
          ) {
            dispatch(
              initializeDisplayGroup(
                configuration.deeplink.multipleResultsDislayGroups
              )
            );
          }
          // could add something here if theres no results (like show a message or do the default search with Toast message)
          // today it will just do the config default
        }
        dispatch({
          type: INITIALIZE_INVENTORY,
          vehicles: res.data.vehicles
        });
        dispatch({
          type: INITIALIZE_SORTING,
          sorting: configuration.settings.sorting || []
        });
        dispatch({
          type: INITIALIZE_FILTERS,
          filters: res.data.filters
        });
        dispatch({
          type: INITIALIZE_PAGING,
          paging: res.data.paging
        });
        dispatch({
          type: UPDATE_LOADING_INDICATOR,
          loadingIndicator: objectAssign({}, loadingIndicator, {
            inventory: false,
            filters: false
          })
        });
      }
      // TODO: add error handling here
    });
  };
}

export function downloadInventory() {
  return function(dispatch, getState) {
    const { filters, sessionContext, sorting } = getState();
    const request = {
      filters: buildFilterRequest(filters),
      sorting,
      source: sessionContext.sources[0]
    };

    ezApi.exportInventory(request, sessionContext.sessionToken).then(res => {
      const blob = new Blob([res.data], { type: "text/csv" });

      FileSaver.saveAs(
        blob,
        "results" + formatDateTime(new Date(), "MM-DD-YYYY") + ".csv"
      );
    });
  };
}
