import React from "react";
import PropTypes from "prop-types";
import * as Icons from "@cx/ui/Icons";

const Icon = ({ config }) => {
  const RequestedIcon = Icons[config.name];
  return <RequestedIcon />;
};

Icon.propTypes = {
  config: PropTypes.object
};

export default Icon;
