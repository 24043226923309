import {
  LOAD_MORE_INVENTORY,
  INITIALIZE_PAGING,
  UPDATE_PAGING
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function pagingReducer(state = initialState.paging, action) {
  switch (action.type) {
    case LOAD_MORE_INVENTORY:
      return action.paging;
    case UPDATE_PAGING:
      return action.paging;
    case INITIALIZE_PAGING:
      return action.paging;
    default:
      return state;
  }
}
