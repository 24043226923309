import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import AccordionGroup from "@cx/ui/AccordionGroup";
import Header from "./Header";
import * as filtersActions from "../../../actions/filtersActions";

class CollapsibleFilter extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    config: PropTypes.object,
    filter: PropTypes.object,
    filtersActions: PropTypes.object,
    isLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      open:
        props.filter.options && props.filter.options.expanded !== undefined
          ? props.filter.options.expanded
          : props.config.collapsible === "expanded"
    };
  }

  toggleAccordion = open => {
    this.setState({
      open
    });
    this.props.filtersActions.toggleCollapsibleFilter(
      this.props.filter.category,
      open
    );
  };

  render() {
    const { config, filter, isLoading, children } = this.props;
    return (
      <AccordionGroup.Container
        htmlId={`filter-textbox-list-${filter.category}`}
        independent
      >
        <AccordionGroup.Item
          header={
            <Header
              showFilterCount={
                ["all", "filters"].indexOf(config.counts || -1) > -1
              }
              hideZeroCounts={config.zeroResults !== "hide"}
              filters={filter.values}
              title={config.title || filter.category}
            />
          }
          className={`${config.collapsible ? "collapsible" : "hide-carat"}`}
          eventKey="1"
          htmlId={`filter-textbox-list-${filter.category}-1`}
          defaultExpanded={this.state.open}
          expanded={this.state.open}
          onToggle={this.toggleAccordion}
        >
          {isLoading ? (
            <LoadingIndicator
              htmlId={`${filter.category}-filter-loading-indicator`}
            />
          ) : (
            <div>{children}</div>
          )}
        </AccordionGroup.Item>
      </AccordionGroup.Container>
    );
  }
}

CollapsibleFilter.defaultProps = {
  config: {}
};

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(CollapsibleFilter);
