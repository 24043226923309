import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Badge from "@cx/ui/Badge";
import ListContainer from "@cx/ui/ListContainer";
import Button from "@cx/ui/Button";
import Checkbox from "react-bootstrap/lib/Checkbox";
import * as validation from "./validation";
import * as filtersActions from "../../../actions/filtersActions";

const emptyArray = [];

class CheckboxList extends React.Component {
  // = ({ config = {}, filter, filtersActions }) => {
  static propTypes = {
    config: PropTypes.object,
    filter: PropTypes.object,
    filtersActions: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedValues: this.props.filter.selectedValues || []
    };
  }

  mapFilters = () => {
    const { config, filter } = this.props;
    return filter.values.map((term, index) => {
      if (config.zeroResults !== "hide" || term.count > 0) {
        const uniqueIdentifier = `${term.value}-${index}`;
        return (
          <Checkbox
            key={uniqueIdentifier}
            id={`${term.value}-checkbox`}
            checked={
              (this.state.selectedValues || emptyArray).indexOf(term.value) > -1
            }
            disabled={config.zeroResults === "disable" && term.count === 0}
            value={term.value || ""}
            onChange={this.onClick}
          >
            <span className="filter-item">
              <span
                className="filter-item-content"
                title={term.value || config.emptyText || "[Unknown]"}
              >
                {term.value || config.emptyText || "[Unknown]"}
              </span>
              {["all", "results"].indexOf(config.counts || -1) > -1 && (
                <span className="filter-item-count">
                  <Badge htmlId="term-count">{term.count}</Badge>
                </span>
              )}
            </span>
          </Checkbox>
        );
      } else {
        return null;
      }
    });
  };

  onClick = e => {
    const { config } = this.props;
    if (config.apply === true) {
      this.updateSelectedValues(e);
    } else {
      this.filterResults(e);
    }
  };

  filterResults = e => {
    const { config, filter, filtersActions } = this.props;
    if (e.target.checked === true) {
      filtersActions.filterResults(
        filter.category,
        config.match || "anyTerm",
        config.select === "multi"
          ? (filter.selectedValues || emptyArray).concat(e.target.value)
          : [e.target.value]
      );
    } else {
      filtersActions.filterResults(
        filter.category,
        config.match || "anyTerm",
        filter.selectedValues.filter(x => {
          return x !== e.target.value;
        })
      );
    }
  };

  updateSelectedValues = e => {
    const selectedValues =
      e.target.checked === true
        ? this.state.selectedValues.concat(e.target.value)
        : this.state.selectedValues.filter(x => x !== e.target.value);
    this.setState({
      selectedValues
    });
  };

  applySelectedValues = () => {
    const { config, filter, filtersActions } = this.props;
    filtersActions.filterResults(
      filter.category,
      config.match || "anyTerm",
      this.state.selectedValues
    );
  };

  resetSelectedValues = () => {
    const { config, filter, filtersActions } = this.props;
    filtersActions.filterResults(
      filter.category,
      config.match || "anyTerm",
      emptyArray
    );
  };

  // Use this once ListContainer is fixed or replaced
  displayShowMore = () => {
    const { config, filter } = this.props;
    return validation.shouldDisplayShowMore(filter, config) === true;
  };

  render() {
    const { config, filter } = this.props;
    return (
      <div>
        <ListContainer
          htmlId="filterListContainer"
          className="listcontainer"
          displayShowMore={this.displayShowMore()}
          showCount={false}
          numRecordsInitiallyVisible={config.showMoreAfter}
          list={this.mapFilters()}
        />
        {config.apply === true && (
          <div className="filter-buttons">
            <Button
              htmlId={`${filter.category}-reset-button`}
              onClick={this.resetSelectedValues}
            >
              Reset
            </Button>
            <Button
              htmlId={`${filter.category}-apply-button`}
              onClick={this.applySelectedValues}
              bsStyle="primary"
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(CheckboxList);
