import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@cx/ui/Button";
import * as selectedVehiclesActions from "../../../actions/selectedVehiclesActions";
import ezMapper from "../../ezMapper";
import objectAssign from "object-assign";

const CancelButton = ({
  componentData,
  index,
  selectedVehiclesActions,
  displayGroups
}) => {
  const handleClick = () => {
    // TODO: if we ever want multiple selected vehicles to show, this will need to remove THIS vehicle instead of all selected vehicles
    selectedVehiclesActions.updateSelectedVehicles([]);
  };

  const { config } = componentData;

  return (
    <Button
      htmlId={`toggle-button-${index}`}
      onClick={handleClick}
      className={config.options && config.options.className}
      bsStyle={(config.options && config.options.bsStyle) || "default"}
    >
      {config.components
        ? config.components.map(component => {
            return ezMapper(
              displayGroups,
              objectAssign({}, componentData, { config: component })
            );
          })
        : (config.options && config.options.text) || "Cancel"}
    </Button>
  );
};

CancelButton.propTypes = {
  componentData: PropTypes.object,
  displayGroups: PropTypes.array,
  index: PropTypes.number,
  options: PropTypes.object,
  selectedVehiclesActions: PropTypes.object
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectedVehiclesActions: bindActionCreators(
      selectedVehiclesActions,
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelButton);
