import {
  SORT_INVENTORY,
  INITIALIZE_SORTING,
  UPDATE_SORTING
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function sortingReducer(state = initialState.sorting, action) {
  switch (action.type) {
    case SORT_INVENTORY:
      return action.sorting;
    case UPDATE_SORTING:
      return action.sorting;
    case INITIALIZE_SORTING:
      return action.sorting;
    default:
      return state;
  }
}
