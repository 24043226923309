export function noChange(selectedValues, initialValues) {
  if (selectedValues.length !== initialValues.length) {
    return false;
  } else {
    selectedValues.forEach(selected => {
      if (initialValues.indexOf(selected) === -1) {
        return false;
      }
    });
  }
  return true;
}
