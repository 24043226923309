export function validateMinMax(selectedMin, selectedMax, min, max) {
  const regex = /^([-]?)(\d*\.?\d*|\d{1,3}(,\d{3})*(\.\d+)?)$/;
  let maxWarning = "";
  if (!regex.test(selectedMax)) {
    maxWarning = "Numbers only";
  } else if (parseInt(selectedMax, 10) > parseInt(max, 10)) {
    maxWarning = `Value cannot be greater than ${max}`;
  } else if (parseInt(selectedMax, 10) < parseInt(selectedMin, 10)) {
    maxWarning = "Value cannot be less than minimum";
  }
  let minWarning = "";
  if (!regex.test(selectedMin)) {
    minWarning = "Numbers only";
  } else if (parseInt(selectedMin, 10) < parseInt(min, 10)) {
    minWarning = `Value cannot be less than ${min}`;
  } else if (parseInt(selectedMin, 10) > parseInt(selectedMax, 10)) {
    minWarning = "Value cannot be greater than maximum";
  }
  return { minWarning, maxWarning };
}
