import React from "react";
import PropTypes from "prop-types";
import Table from "@cx/ui/Table";
import { getColumns, getData } from "./ezEquipmentMapper";

const EzEquipment = ({ config, record }) => {
  const columns = getColumns(config);
  const data = getData(record);
  const options = config.options || {};

  return (
    <Table
      className={options.className}
      columns={columns}
      data={data}
      dataDensity={options.dataDensity}
      displayFilter={options.displayFilter}
      displayHeader={options.displayHeader}
      displayPageSizeSelector={options.displayPageSizeSelector}
      emptyText={options.emptyText}
      enablePagination={options.enablePagination || false}
      highlightOnHover={options.highlightOnHover}
      htmlId={`EzEquipment-${config.id}`}
      pageSize={options.pageSize || data.length}
      rowClassName={() => "table-row"}
      scrollX={options.scrollX}
      scrollY={options.scrollY}
      sortableColumns={options.sortableColumns}
    />
  );
};

EzEquipment.propTypes = {
  config: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
};

export default EzEquipment;
