import objectAssign from "object-assign";
import * as common from "../../utils/common";
import * as formatters from "../../utils/formatters";

const supportedColumns = {
  Category: {
    dataIndex: "categories[0].header",
    key: 0,
    title: "Category"
  },
  OemCode: {
    dataIndex: "oemOptionCode",
    key: 1,
    title: "OEM Code"
  },
  Description: {
    dataIndex: "extendedDescription",
    key: 2,
    title: "Description"
  },
  InvoicePrice: {
    dataIndex: "pricing.invoice.amount",
    key: 3,
    title: "Invoice Price",
    render: formatters.formatCurrency
  },
  MSRP: {
    dataIndex: "pricing.msrp.amount",
    key: 4,
    title: "MSRP",
    render: formatters.formatCurrency
  }
};

export const getColumns = config => {
  return config.columns.map(column => supportedColumns[column]);
};

const hasInvoicePrice = record => {
  return (
    record &&
    record.pricing &&
    record.pricing.invoice &&
    record.pricing.invoice.amount &&
    common.isNumeric(record.pricing.invoice.amount) &&
    common.number(record.pricing.invoice.amount) > 0
  );
};

const hasMSRP = record => {
  return (
    record &&
    record.pricing &&
    record.pricing.msrp &&
    record.pricing.msrp.amount &&
    common.isNumeric(record.pricing.msrp.amount) &&
    common.number(record.pricing.msrp.amount) > 0
  );
};

export const getData = record => {
  const options =
    (record && record.vehicle && record.vehicle.installedEquipment) || [];
  return options
    .map((option, index) => {
      return objectAssign({}, option, { key: index });
    })
    .sort((a, b) => (hasInvoicePrice(a) || hasMSRP(a) ? -1 : 1));
};
