import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

/* eslint-disable prettier/prettier */
// https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
const sanitizeOptions = {
  allowedTags: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "blockquote",
    "p",
    "a",
    "ul",
    "ol",
    "nl",
    "li",
    "b",
    "i",
    "img",
    "strong",
    "em",
    "strike",
    "code",
    "hr",
    "br",
    "div",
    "table",
    "thead",
    "caption",
    "tbody",
    "tr",
    "th",
    "td",
    "pre",
    "iframe"
  ],
  allowedAttributes: {
    a: ["href", "name", "target"],
    iframe: ["src", "height", "width", "frameborder"],
    img: ["src", "height", "width"],
    "*": ["class"]
  },
  selfClosing: [
    "img",
    "br",
    "hr",
    "area",
    "base",
    "basefont",
    "input",
    "link",
    "meta"
  ],
  allowedSchemes: ["http", "https", "ftp", "mailto"],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ["href", "src", "cite"],
  allowProtocolRelative: true
};
/* eslint-enable prettier/prettier */

/* eslint-disable react/no-danger */
// HERE BE DRAGONS:
// It is generally considered to be a BAD IDEA to render
// arbitrary HTML from a dynamic source. Here, we are
// deliberately making the security trade-off to get
// increased flexiblity and rapid feature development.
const Static = ({ config }) => {
  return (
    <div
      id={config.options ? config.options.id : undefined}
      className={config.options ? config.options.className : undefined}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(config.content, sanitizeOptions)
      }}
    />
  );
};
/* eslint-enable react/no-danger */

Static.propTypes = {
  config: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.string,
    className: PropTypes.string
  })
};

export default Static;
