import {
  UPDATE_DISPLAY_GROUP,
  INITIALIZE_DISPLAY_GROUP
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function displayGroupReducer(
  state = initialState.displayGroups,
  action
) {
  switch (action.type) {
    case UPDATE_DISPLAY_GROUP:
      return action.displayGroups;
    case INITIALIZE_DISPLAY_GROUP:
      return action.displayGroups;
    default:
      return state;
  }
}
