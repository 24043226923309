import Details from "./Details";
import Image from "./Image";
import Title from "./Title";
import Html from "./Html";
import "./_cards.scss";

export default {
  Image,
  Details,
  Title,
  Html
};
