import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const scrollStore = {};

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.sectionRef = React.createRef();
    this.layout = props.layout || {};
    this.disableStickyScroll = this.layout.disableStickyScroll === true;

    /* eslint-disable */
    this.GridComponent = styled.div`
      min-width: 0;
      min-height: 0;
      overflow-y: auto;
      ${this.layout.displayRow &&
        `
      -ms-grid-row: ${this.layout.displayRow.split("/")[0]};
      grid-row-start: ${this.layout.displayRow.split("/")[0]};
      -ms-grid-row-span: ${this.layout.displayRow.split("/")[1]};
      grid-row-end: span ${this.layout.displayRow.split("/")[1]};
      `}
      ${this.layout.displayColumn &&
        `
      -ms-grid-column: ${this.layout.displayColumn.split("/")[0]};
      grid-column-start: ${this.layout.displayColumn.split("/")[0]};
      -ms-grid-column-span: ${this.layout.displayColumn.split("/")[1]};
      grid-column-end: span ${this.layout.displayColumn.split("/")[1]}
      `}
      ${this.layout.align &&
        `
      -ms-grid-row-align: ${this.layout.align};
      align-self: ${this.layout.align};
      `}
      ${this.layout.justify &&
        `
      -ms-grid-column-align: ${this.layout.justify};
      justify-self: ${this.layout.justify};
      `}
      ${this.layout.padding !== false &&
        `
      padding: 16px;
      `}
      ${this.layout.display &&
        `
      display: ${this.layout.display};
      `}
      ${this.layout.border &&
        `
      border: ${this.layout.border};
      `}
    `;
    /* eslint-enable */
  }

  componentDidMount() {
    this.restoreScrollPosition();
  }

  componentWillUnmount() {
    this.saveScrollPosition();
  }

  restoreScrollPosition() {
    if (this.disableStickyScroll) {
      return;
    }
    const pos = scrollStore[this.layout.id];
    if (pos) {
      const section = this.sectionRef.current;
      section.scrollTop = section.scrollHeight - pos;
    }
  }

  saveScrollPosition() {
    if (this.disableStickyScroll) {
      return;
    }
    const section = this.sectionRef.current;
    scrollStore[this.layout.id] = section.scrollHeight - section.scrollTop;
  }

  render() {
    const { children, className, ...other } = this.props;
    return (
      <this.GridComponent
        className={`grid-section ${className || ""}`}
        {...other}
        ref={this.sectionRef}
      >
        {children}
      </this.GridComponent>
    );
  }
}

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.func
  ]),
  className: PropTypes.string,
  layout: PropTypes.object
};

export default Section;
