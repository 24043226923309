import { UPDATE_SELECTED_VEHICLES } from "./actionTypes";

export function updateSelectedVehicles(selectedVehicles) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_SELECTED_VEHICLES,
      selectedVehicles
    });
  };
}
