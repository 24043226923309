import React from "react";
import CollapsibleFilter from "./CollapsibleFilter";
import CheckboxList from "./CheckboxList";
import MinMaxSelector from "./MinMaxSelector";
import SearchableSelector from "./SearchableSelector";
import SearchBox from "./SearchBox";

export default function mapComponents(config, filter, isLoading) {
  switch (config.type) {
    case "searchBox":
      return (
        <CollapsibleFilter
          config={config.options}
          filter={filter}
          isLoading={isLoading}
          key={config.id}
        >
          <SearchBox options={config.options} filter={filter} />
        </CollapsibleFilter>
      );
    case "standaloneSearchBox":
      return <SearchBox options={config.options} filter={filter} />;
    case "checkboxList":
      return (
        <CollapsibleFilter
          config={config.options}
          filter={filter}
          isLoading={isLoading}
          key={config.id}
        >
          <CheckboxList config={config.options} filter={filter} />
        </CollapsibleFilter>
      );
    case "rangeTextboxes":
      return (
        <CollapsibleFilter
          config={config.options}
          filter={filter}
          isLoading={isLoading}
          key={config.id}
        >
          <MinMaxSelector config={config.options} filter={filter} />
        </CollapsibleFilter>
      );
    case "searchableSelect":
      return (
        <CollapsibleFilter
          key={config.id}
          config={config.options}
          filter={filter}
          isLoading={isLoading}
        >
          <SearchableSelector config={config.options} filter={filter} />
        </CollapsibleFilter>
      );
    default:
      return null;
  }
}
