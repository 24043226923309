import {
  SORT_INVENTORY,
  UPDATE_INVENTORY,
  UPDATE_PAGING,
  UPDATE_LOADING_INDICATOR,
  UPDATE_SELECTED_VEHICLES
} from "./actionTypes";
import * as ezApi from "../api/ezApi";
import objectAssign from "object-assign";
import { buildFilterRequest, updateFilters } from "./filtersActions";

export function sortVehicles(name, isDescending) {
  return function(dispatch, getState) {
    // TODO: Add the ability to sort by multiple categories
    const { filters, paging, sessionContext, loadingIndicator } = getState();
    const request = {
      filters: buildFilterRequest(filters),
      paging,
      sorting: [{ name, isDescending }],
      source: sessionContext.sources[0]
    };
    dispatch({
      type: UPDATE_LOADING_INDICATOR,
      loadingIndicator: objectAssign({}, loadingIndicator, {
        inventory: true,
        filters: false
      })
    });
    ezApi.getInventory(request, sessionContext.sessionToken).then(res => {
      /* TODO: find way to improve filters efficiency. Server side config?
       Mapping it here would save processing power every render,
       however, mapping it in the component allows us to have more options
       for filtering, like a separate component to render in a different use case
       */
      if (res.data.success === true) {
        dispatch(updateFilters(res.data.filters));
        dispatch({
          type: UPDATE_SELECTED_VEHICLES,
          selectedVehicles: []
        });
        dispatch({
          type: UPDATE_INVENTORY,
          vehicles: res.data.vehicles
        });
        dispatch({
          type: UPDATE_PAGING,
          paging: res.data.paging
        });
        dispatch({
          type: SORT_INVENTORY,
          sorting: [{ name, isDescending }]
        });
        dispatch({
          type: UPDATE_LOADING_INDICATOR,
          loadingIndicator: objectAssign({}, loadingIndicator, {
            inventory: false,
            filters: false
          })
        });
      }
      // TODO: add error handling here
    });
  };
}
