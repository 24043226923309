import { UPDATE_LOADING_INDICATOR } from "../actions/actionTypes";
import initialState from "./initialState";

export default function loadingIndicatorReducer(
  state = initialState.loadingIndicator,
  action
) {
  switch (action.type) {
    case UPDATE_LOADING_INDICATOR:
      return action.loadingIndicator;
    default:
      return state;
  }
}
