import React from "react";
import PropTypes from "prop-types";
import Badge from "@cx/ui/Badge";

const Header = ({ title, showFilterCount, hideZeroCounts, values }) => {
  return (
    <span className="collapsible-filter-header">
      <span className="collapsible-filter-header-title">{title}</span>
      {showFilterCount && values && (
        <Badge
          htmlId="filter-count-badge"
          className="collapsible-filter-header-count"
        >
          {hideZeroCounts === true
            ? values.filter(x => x.count > 0).length
            : values.length}
        </Badge>
      )}
    </span>
  );
};

Header.propTypes = {
  hideZeroCounts: PropTypes.bool,
  showFilterCount: PropTypes.bool,
  title: PropTypes.string,
  values: PropTypes.array
};

export default Header;
