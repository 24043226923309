import { UPDATE_SELECTED_VEHICLES } from "../actions/actionTypes";
import initialState from "./initialState";

export default function selectedVehiclesReducer(
  state = initialState.selectedVehicles,
  action
) {
  switch (action.type) {
    case UPDATE_SELECTED_VEHICLES:
      return action.selectedVehicles;
    default:
      return state;
  }
}
