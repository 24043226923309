import {
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_SUCCESS
} from "../actions/actionTypes";
import initialState from "./initialState";

export default function authReducer(
  state = initialState.sessionContext,
  action
) {
  switch (action.type) {
    case AUTHENTICATION_FAILURE:
      return action.sessionContext;
    case AUTHENTICATION_SUCCESS:
      return action.sessionContext;
    default:
      return state;
  }
}
