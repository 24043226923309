import formatter from "number-formatter";
import * as common from "./common";
import moment from "moment";

export function formatCurrency(value) {
  const number = common.hasValue(value) && !isNaN(value) ? value : 0;
  if (number < 0) {
    return "(" + formatter("$#,##0.", Math.abs(number)) + ")";
  } else {
    return formatter("$#,##0.", number);
  }
}

export function formatCurrencyDefaultBlank(value) {
  const number = common.hasValue(value) && !isNaN(value) ? value : null;
  if (number === null) {
    return "";
  } else {
    return formatCurrency(number);
  }
}

export function formatNumber(value) {
  const number = common.hasValue(value) && !isNaN(value) ? value : 0;
  return formatter("#,##0.##", number);
}

export function formatNumberDefaultBlank(value) {
  const number = common.hasValue(value) && !isNaN(value) ? value : null;
  if (number === null) {
    return "";
  } else {
    return formatNumber(number);
  }
}
export function formatPercentage(value) {
  const number = common.hasValue(value) && !isNaN(value) ? value : 0;
  return formatter("#0.## %", number);
}

export function formatDateTime(value, format) {
  if (common.hasValue(value)) {
    const m = moment(value);
    if (m.isValid() && common.hasValue(format)) {
      return m.format(format);
    } else {
      return value;
    }
  } else {
    return value;
  }
}
