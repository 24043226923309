import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Demo from "./Demo";
import ezMapper from "../ezMapper";
import EzLayout from "../EzLayout";

const EzVu = ({ viewConfig, demo, displayGroups }) => {
  return (
    <div className="ez-vu-wrapper page-wrapper__content-only">
      {demo === "true" && (
        <Demo
          configuration={viewConfig.configuration}
          configurationId={viewConfig.configId}
        />
      )}
      {viewConfig.configId && (
        <EzLayout.Wrapper layout={viewConfig.configuration.layout}>
          {viewConfig.configuration.components.map(component => {
            return ezMapper(displayGroups, { config: component });
          })}
        </EzLayout.Wrapper>
      )}
    </div>
  );
};

EzVu.propTypes = {
  demo: PropTypes.string,
  displayGroups: PropTypes.array,
  viewConfig: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    viewConfig: state.viewConfig
  };
}

export default connect(
  mapStateToProps,
  null
)(EzVu);
