import React from "react";
import Button from "@cx/ui/Button";
import * as filtersActions from "../../../../actions/filtersActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

const ClearFilters = ({ filtersActions }) => {
  const clearFilters = () => {
    filtersActions.clearFilters();
  };

  return (
    <Button
      htmlId="clear-filters-button"
      bsStyle="link"
      onClick={clearFilters}
      size="default"
    >
      Clear all
    </Button>
  );
};

ClearFilters.propTypes = {
  filtersActions: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ClearFilters);
