import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextArea from "@cx/ui/TextArea";
import TextInput from "@cx/ui/TextInput";
import Button from "@cx/ui/Button";
import ModalDialog from "@cx/ui/ModalDialog";
import * as viewConfigActions from "../../../actions/viewConfigActions";

class Demo extends React.Component {
  static propTypes = {
    configuration: PropTypes.object.isRequired,
    configurationId: PropTypes.string,
    viewConfigActions: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      modalConfig: "",
      showModal: false,
      configurationId: ""
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  openConfigModal = () => {
    // cheap way to have local state separate from store
    this.setState({
      showModal: true,
      modalConfig: JSON.stringify(this.props.configuration, undefined, 2)
    });
  };

  updateModalConfig = (e, isValid, domEvent) => {
    this.setState({
      modalConfig: e.target.value
    });
  };

  saveModalConfig = () => {
    this.props.viewConfigActions.updateViewConfig({
      configuration: JSON.parse(this.state.modalConfig),
      configId: "custom"
    });
    // closing modal is a cheap way to rehydrate the demo modal with the new configId
    this.setState({
      showModal: false
    });
  };

  getConfiguration = () => {
    this.props.viewConfigActions.getConfig(this.state.configurationId);
    // closing modal is a cheap way to rehydrate the demo modal with the config response.
    this.setState({
      showModal: false
    });
  };

  updateConfigurationId = e => {
    this.setState({
      configurationId: e.target.value
    });
  };

  render() {
    return (
      <div className="config-settings">
        <div>
          <Button htmlId="config-button" onClick={this.openConfigModal}>
            Manual Config Edit
          </Button>
        </div>
        <ModalDialog
          htmlId="config-modal"
          className="sample-modal-dialog"
          show={this.state.showModal}
          onHide={this.toggleModal}
          header={
            <ModalDialog.Title>{this.props.configurationId}</ModalDialog.Title>
          }
        >
          <h5 style={{ color: "#F00" }}>
            I hope you know what you&apos;re doing...
          </h5>
          <div>
            <TextArea
              htmlId="modal-config-text-area"
              label="Config"
              name="modalConfig"
              rows={10}
              onChange={this.updateModalConfig}
              value={this.state.modalConfig}
            />
          </div>
          <div>
            <TextInput
              htmlId="modal-config-get-input"
              inputPrefix="Config Name"
              label="Load New Config"
              name="modal-config-get-input"
              onChange={this.updateConfigurationId}
              value={this.state.configurationId}
            >
              <Button
                buttonStyle="primary"
                disabled={
                  this.state.configurationId === this.props.configurationId
                }
                onClick={this.getConfiguration}
                htmlId="get-config-button"
              >
                Load Config
              </Button>
            </TextInput>
          </div>
          <div>
            <Button
              htmlId="modal-config-save-button"
              onClick={this.saveModalConfig}
            >
              Update Session Config
            </Button>
          </div>
        </ModalDialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    viewConfigActions: bindActionCreators(viewConfigActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Demo);
