import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Badge from "@cx/ui/Badge";
import SearchableSelect from "@cx/ui/SearchableSelect";
import Button from "@cx/ui/Button";
import * as validation from "./validation";
import * as filtersActions from "../../../actions/filtersActions";

const emptyArray = [];

class SearchableSelector extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    filter: PropTypes.object,
    filtersActions: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedValues: props.filter.selectedValues || emptyArray
    };
  }

  mapFilters = () => {
    const values =
      this.props.config.hideZeroCounts === true
        ? this.props.filter.values.filter(x => x.count > 0)
        : this.props.filter.values;
    const options = [];
    values.forEach((term, index) => {
      if (this.props.config.zeroResults !== "hide" || term.count > 0) {
        options.push({
          key: `${this.props.filter.category}-${index}`,
          value: term.value || "",
          count: term.count,
          label: term.value || this.props.config.emptyText || "[Unknown]"
        });
      }
    });
    return options;
  };

  mapSelectedValues = () => {
    return this.state.selectedValues.map(value => {
      return {
        value,
        label: value || this.props.config.emptyText || "[Unknown]"
      };
    });
  };

  onApplyClick = () => {
    this.props.filtersActions.filterResults(
      this.props.filter.category,
      this.props.config.match || "anyTerm",
      this.state.selectedValues
    );
  };

  onResetClick = () => {
    this.setState(
      {
        selectedValues: []
      },
      () => {
        this.onApplyClick();
      }
    );
  };

  onChange = (e, isValid, domEvent) => {
    // TODO: event is fired a bunch of times. we need to limit the amount of times we try and setstate or its gonna hurt on multi select.
    if (domEvent && domEvent.type === "blur") {
      this.onBlur();
    }
    this.setState({
      selectedValues: e.target.value.map(x => {
        return x.value;
      })
    });
  };

  onBlur = () => {
    // if we don't compare the current value of the textbox with the selectedvalues, you will cause an infinite loop of calling our API.
    if (
      this.props.config.options === undefined ||
      this.props.config.options.apply !== true
    ) {
      if (
        (this.state.selectedValues || []).length !==
        (this.props.filter.selectedValues || []).length
      ) {
        return this.onApplyClick();
      }
      this.state.selectedValues.forEach(x => {
        if ((this.props.filter.selectedValues || []).indexOf(x) === -1) {
          return this.onApplyClick();
        }
      });
    }
  };

  listItem = option => {
    return (
      <span key={option.key}>
        {option.label}
        {this.props.config.showCounts && (
          <Badge htmlId="term-count">{option.count}</Badge>
        )}
      </span>
    );
  };

  customToken = option => {
    return (
      <span className="searchable-selector-pill-text" title={option.label}>
        {option.label}
      </span>
    );
  };

  render() {
    const { filter, config } = this.props;
    const applyDisabled =
      this.state.selectedValues.length === 0 ||
      validation.noChange(
        this.state.selectedValues,
        filter.selectedValues || emptyArray
      );
    return (
      <div>
        <SearchableSelect
          customSelectableItem={this.listItem}
          className="searchable-selector"
          customToken={this.customToken}
          enableMultiSelect={config.enableMultiSelect === true}
          displayLabel={false}
          displayPlaceholder={false}
          filterBy={["label"]}
          htmlId={`${filter.category}-searchable-select`}
          name={`${filter.category}-searchable-select`}
          onChange={this.onChange}
          onBlur={this.onBlur}
          options={this.mapFilters()}
          value={this.mapSelectedValues()}
        />
        {config.options && config.options.apply === true && (
          <Button
            htmlId={`${filter.category}-reset-button`}
            onClick={this.onResetClick}
          >
            Reset
          </Button>
        )}
        {config.options && config.options.reset === true && (
          <Button
            disabled={applyDisabled}
            htmlId={`${filter.category}-apply-button`}
            onClick={this.onApplyClick}
            bsStyle="primary"
          >
            Apply
          </Button>
        )}
      </div>
    );
  }
}

SearchableSelector.defaultProps = {
  config: {}
};

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(SearchableSelector);
