import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import mapComponents from "./ezFilterMapper";
import * as common from "../../utils/common";
import EzLayout from "../EzLayout";

class EzFilter extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.scrollOffset = null;
  }

  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    if (prevProps.isLoading === false && this.props.isLoading === true) {
      const section = this.scrollRef.current.sectionRef.current;
      this.scrollOffset = section.scrollHeight - section.scrollTop;
    }
    return null;
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      prevProps.isLoading === true &&
      this.props.isLoading === false &&
      this.scrollOffset
    ) {
      const section = this.scrollRef.current.sectionRef.current;
      section.scrollTop = section.scrollHeight - this.scrollOffset;
    }
  };

  mapFilters = () => {
    const filterComponents = [];
    const filters =
      this.props.config.filters ||
      this.createFilterConfig(this.props.settings.filters);
    // we don't know if config filters are real or valid. This means a messy check for each one against the filters
    // tried to use a dictionary here, but it gets too hairy without knowing whats in at least one of the filter lists. Too many moving parts.
    // loop through each config filter
    filters.forEach(filterConfig => {
      // try and match the config filter to a filter from filters in the store
      const filter = this.matchConfigToFilter(filterConfig.dataIndex);
      // is there a match
      if (common.hasValue(filter)) {
        // add the filter to the list
        filterComponents.push(
          // create the component to be rendered
          mapComponents(filterConfig, filter, this.props.isLoading)
        );
      }
    });
    return filterComponents;
  };

  createFilterConfig = settingsFilters => {
    return settingsFilters.map(x => {
      return {
        type: x.ezFilterType,
        options: x.options,
        dataIndex: x.category
      };
    });
  };

  matchConfigToFilter = dataIndex => {
    // try and find a match
    return this.props.filters.find(filter => {
      return filter.category === dataIndex;
    });
  };

  render() {
    const { config, filters, isLoading } = this.props;
    const className =
      config.options && config.options.borderless === true
        ? "ezFilters borderless"
        : config.options && config.options.divider === true
        ? "ezFilters divider"
        : "ezFilters";
    return (
      <EzLayout.Section layout={config.layout} ref={this.scrollRef}>
        <div className={className}>
          <EzLayout.Wrapper layout={config.layout}>
            {filters.length === 0 && isLoading !== true
              ? config.options && config.options.noFiltersMessage
                ? config.options.noFiltersMessage
                : "No Filters Available"
              : this.mapFilters()}
          </EzLayout.Wrapper>
        </div>
      </EzLayout.Section>
    );
  }
}

EzFilter.propTypes = {
  config: PropTypes.object.isRequired,
  filters: PropTypes.array,
  isLoading: PropTypes.bool,
  settings: PropTypes.object
};

function mapStateToProps(state) {
  return {
    filters: state.filters,
    isLoading: state.loadingIndicator.filters,
    settings: state.viewConfig.configuration.settings
  };
}

export default connect(
  mapStateToProps,
  null
)(EzFilter);
