export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";

export const UPDATE_SELECTED_VEHICLES = "UPDATE_SELECTED_VEHICLES";

export const LOAD_MORE_INVENTORY = "LOAD_MORE_INVENTORY";

export const INITIALIZE_INVENTORY = "INITIALIZE_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";

export const SORT_INVENTORY = "SORT_INVENTORY";
export const UPDATE_SORTING = "UPDATE_SORTING";
export const INITIALIZE_SORTING = "INITIALIZE_SORTING";

export const INITIALIZE_PAGING = "INITIALIZE_PAGING";
export const UPDATE_PAGING = "UPDATE_PAGING";

export const FILTER_INVENTORY = "FILTER_INVENTORY";
export const INITIALIZE_FILTERS = "INITIALIZE_FILTERS";
export const TOGGLE_COLLAPSIBLE_FILTER = "TOGGLE_COLLAPSIBLE_FILTER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";

export const UPDATE_LOADING_INDICATOR = "UPDATE_LOADING_INDICATOR";

export const UPDATE_VIEW_CONFIG = "UPDATE_VIEW_CONFIG";

export const UPDATE_DISPLAY_GROUP = "UPDATE_DISPLAY_GROUP";
export const INITIALIZE_DISPLAY_GROUP = "INITIALIZE_DISPLAY_GROUP";

export const INITIALIZE_QUERY_STRING_PARAMETERS =
  "INITIALIZE_QUERY_STRING_PARAMETERS";
