import React from "react";
import PropTypes from "prop-types";
import nestedProperty from "nested-property";
import ModalDialog from "@cx/ui/ModalDialog";
import ImageCarousel from "../ImageCarousel";
import * as common from "../../../utils/common";

class Thumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { record, options } = this.props;
    const path =
      common.hasValue(options) && common.hasValue(options.dataIndex)
        ? options.dataIndex
        : "inventoryDetail.sourceVehiclePhotoUrls";
    return (
      <div className="vehicle-views-thumbnail-wrapper">
        <img
          id="vehicleThumb"
          alt="thumbnail of the vehicle"
          className="vehicle-views-thumbnail-image"
          width={options && options.width ? options.width : 200}
          height={options && options.height ? options.height : undefined}
          onClick={
            nestedProperty.get(record, path + ".0")
              ? this.toggleModal
              : undefined
          }
          src={
            nestedProperty.get(record, path + ".0") ||
            require("./defaultImage.png")
          }
        />
        <ModalDialog
          htmlId="image-modal"
          show={this.state.showModal}
          onHide={this.toggleModal}
        >
          <ImageCarousel record={record} dataIndex={options.dataIndex} />
        </ModalDialog>
      </div>
    );
  }
}

Thumbnail.propTypes = {
  imageURL: PropTypes.string,
  noPhotoURL: PropTypes.string,
  options: PropTypes.object,
  record: PropTypes.object.isRequired
};

export default Thumbnail;
