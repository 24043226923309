import { UPDATE_VIEW_CONFIG } from "../actions/actionTypes";
import initialState from "./initialState";

export default function viewConfigReducer(
  state = initialState.viewConfig,
  action
) {
  switch (action.type) {
    case UPDATE_VIEW_CONFIG:
      return action.viewConfig;
    default:
      return state;
  }
}
