import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzLayout from "../EzLayout";
import ezMapper from "../ezMapper";
import objectAssign from "object-assign";
import FullPageModal from "@cx/ui/FullPageModal";
import Button from "@cx/ui/Button";

class EzFullPageModal extends React.Component {
  static propTypes = {
    componentData: PropTypes.object.isRequired,
    displayGroups: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  toggleFullPageModal = () => {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { componentData, displayGroups } = this.props;
    const config = componentData.config;
    const { show } = this.state;
    return (
      <EzLayout.Section layout={config.layout}>
        <EzLayout.Wrapper layout={config.layout}>
          {config.button && (
            <Button
              id={`full-page-modal-button-${config.id}`}
              onClick={this.toggleFullPageModal}
              bsStyle={config.button.bsStyle || "default"}
            >
              {config.button.text}
            </Button>
          )}
          <FullPageModal
            closeButton={config.options && config.options.closeButton}
            show={show}
            onHide={this.toggleFullPageModal}
          >
            {config.components.map(component => {
              return ezMapper(
                displayGroups,
                objectAssign({}, componentData, { config: component })
              );
            })}
          </FullPageModal>
        </EzLayout.Wrapper>
      </EzLayout.Section>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

export default connect(
  mapStateToProps,
  null
)(EzFullPageModal);
