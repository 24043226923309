import React from "react";
import PropTypes from "prop-types";
import ImageCarousel from "../../ImageCarousel";
import EzLayout from "../../../EzLayout";
import Card from "@cx/ui/Card";

const Image = ({ config, record }) => {
  const className =
    config.options && config.options.borderless
      ? "image-card borderless"
      : "image-card";
  return (
    <EzLayout.Section layout={config.layout}>
      <Card htmlId="can-you-believe-this-id" className={className}>
        <ImageCarousel record={record} dataIndex={config.options.dataIndex} />
      </Card>
    </EzLayout.Section>
  );
};

Image.propTypes = {
  config: PropTypes.object,
  record: PropTypes.object
};

export default Image;
