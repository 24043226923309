import React from "react";
import PropTypes from "prop-types";
import EzLayout from "../EzLayout";
import Html from "../reusable/Html";

const EzHtml = ({ config }) => {
  return (
    <EzLayout.Section layout={config.layout}>
      <Html.Static config={config} />
    </EzLayout.Section>
  );
};

EzHtml.propTypes = {
  config: PropTypes.shape({
    layout: PropTypes.shape({
      row: PropTypes.string,
      column: PropTypes.string
    })
  })
};

export default EzHtml;
