import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ezMapper from "../../../ezMapper";
import objectAssign from "object-assign";
import * as vehicleActions from "../../../../actions/vehiclesActions";
import classNames from "classnames";

export const ExportCSV = ({ componentData, displayGroups, vehicleActions }) => {
  const exportCSV = () => {
    vehicleActions.downloadInventory();
  };

  const { config } = componentData;
  return (
    <Button
      htmlId="export-csv-button"
      onClick={exportCSV}
      className={classNames(
        "export-csv-button",
        config.options && config.options.className
      )}
      bsStyle={(config.options && config.options.bsStyle) || "default"}
      size={(config.options && config.options.size) || "default"}
    >
      {config.components
        ? config.components.map(component => {
            return ezMapper(
              displayGroups,
              objectAssign({}, componentData, { config: component })
            );
          })
        : "Export Search"}
    </Button>
  );
};

ExportCSV.propTypes = {
  componentData: PropTypes.object,
  displayGroups: PropTypes.array,
  vehicleActions: PropTypes.object
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    vehicleActions: bindActionCreators(vehicleActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportCSV);
