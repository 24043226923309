import React from "react";
import { object, array } from "prop-types";
import { connect } from "react-redux";
import nestedProperty from "nested-property";
import ezMapper from "../ezMapper";
import objectAssign from "object-assign";

const EzConditional = ({
  componentData,
  displayGroups,
  queryStringParameters
}) => {
  const evaluateKeyValuePairs = () => {
    // might be a better way to do this, but time is short. if the right things are undefined, conditional will be true :(
    for (const x of componentData.config.conditionals) {
      if (x.key in queryStringParameters) {
        if (queryStringParameters[x.key] !== x.value) {
          return false;
        }
      } else if (nestedProperty.get(componentData.record, x.key)) {
        if (nestedProperty.get(componentData.record, x.key) !== x.value) {
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  };

  const components = evaluateKeyValuePairs()
    ? componentData.config.components || []
    : componentData.config.altComponents || [];

  return (
    <>
      {components.map(component => {
        return ezMapper(
          displayGroups,
          objectAssign({}, componentData, { config: component })
        );
      })}
    </>
  );
};

EzConditional.propTypes = {
  componentData: object,
  displayGroups: array,
  queryStringParameters: object,
  record: object
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    queryStringParameters: state.queryStringParameters
  };
}

export default connect(
  mapStateToProps,
  null
)(EzConditional);
