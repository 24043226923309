import React from "react";
import ColumnSort from "./ColumnSort";
import * as common from "../../utils/common";
import ezMapper from "../ezMapper";

export default function mapColumns(columns, displayGroups) {
  return columns.map(column => {
    const width = column.options ? column.options.width : undefined;
    const fixed = column.options ? column.options.fixed : undefined;
    return Object.assign(
      {},
      {
        dataIndex: column.dataIndex,
        key: column.key,
        title: common.hasValue(column.header)
          ? convertHeaderByType(column.header)
          : "",
        width,
        fixed,
        render: (text, record, index) => {
          return ezMapper(displayGroups, {
            config: column.content,
            record,
            text,
            index
          });
        }
      }
    );
  });
}

function convertHeaderByType(header) {
  return {
    text: header.title,
    columnSort: <ColumnSort title={header.title} name={header.name} />
  }[header.type];
}
