import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import Authorization from "./components/Authorization";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import configureStore from "./store/configureStore";
import "./index.scss";
import queryString from "querystring";
import { authenticate } from "./actions/authActions"; // eslint-disable-line import/default
// eslint-disable-line import/default

const store = configureStore();
const params = queryString.parse(window.location.search.substring(1));

store.dispatch(authenticate(params));

render(
  <Provider store={store}>
    <Authorization demo={params.demo} />
  </Provider>,
  document.getElementById("root")
);
// TODO: remove service worker altogether after it is unregistered
unregisterServiceWorker();
