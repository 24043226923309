import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzLayout from "../EzLayout";
import ezMapper from "../ezMapper";
import objectAssign from "object-assign";
import SlidingDrawer from "@cx/ui/SlidingDrawer";
import Button from "@cx/ui/Button";
import IconMenu from "@cx/ui/Icons/IconMenu";

class EzSlidingDrawer extends React.Component {
  static propTypes = {
    componentData: PropTypes.object.isRequired,
    displayGroups: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  toggleSlidingDrawer = () => {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { componentData, displayGroups } = this.props;
    const config = componentData.config;
    const { show } = this.state;
    return (
      <EzLayout.Section layout={config.layout}>
        <EzLayout.Wrapper layout={config.layout}>
          {config.button && (
            <Button
              id={`sliding-drawer-button-${config.id}`}
              onClick={this.toggleSlidingDrawer}
              bsStyle={config.button.bsStyle || "default"}
              className={"floating-Button"}
              style={
                config.button.floating === true
                  ? {
                      position: "fixed",
                      top: config.button.top || "300px",
                      left: "-5px",
                      padding: "5px 8px",
                      zIndex: "1"
                    }
                  : undefined
              }
            >
              {config.button.floating ? <IconMenu /> : config.button.text}
            </Button>
          )}
          <SlidingDrawer
            closeButton={config.options && config.options.closeButton}
            panelWidth={config.options && (config.options.panelWidth || 280)}
            position={config.options && (config.options.position || "right")}
            show={show}
            hide={this.toggleSlidingDrawer}
          >
            {config.components.map(component => {
              return ezMapper(
                displayGroups,
                objectAssign({}, componentData, { config: component })
              );
            })}
          </SlidingDrawer>
        </EzLayout.Wrapper>
      </EzLayout.Section>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

export default connect(
  mapStateToProps,
  null
)(EzSlidingDrawer);
