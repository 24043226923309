import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzLayout from "../EzLayout";
import ezMapper from "../ezMapper";

const EzDetails = ({ selectedVehicles, config, displayGroups }) => {
  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        {selectedVehicles.map(vehicle => {
          return config.components.map(component => {
            return ezMapper(displayGroups, {
              config: component,
              record: vehicle
            });
          });
        })}
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

EzDetails.propTypes = {
  config: PropTypes.object,
  displayGroups: PropTypes.array,
  selectedVehicles: PropTypes.array
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    selectedVehicles: state.selectedVehicles
  };
}

export default connect(
  mapStateToProps,
  null
)(EzDetails);
