export function hasValue(value) {
  return value !== undefined && value !== null && value !== "";
}

export function isNumeric(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function number(value) {
  return Number(parseFloat(value));
}
