import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzVu from "../EzVu";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const Authorization = ({ sessionContext, demo }) => {
  if (sessionContext.isLoading === true) {
    return <LoadingIndicator htmlId="authorizationInProgress" size="large" />;
  } else {
    if (sessionContext.authenticated) {
      return <EzVu demo={demo} />;
    } else {
      return (
        <div>
          <h5>Access Denied</h5>
          {sessionContext.errorMessage.map(text => (
            <p key={text}>
              <span>{text}</span>
            </p>
          ))}
        </div>
      );
    }
  }
};

Authorization.propTypes = {
  demo: PropTypes.string,
  sessionContext: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    sessionContext: state.sessionContext
  };
}

export default connect(
  mapStateToProps,
  null
)(Authorization);
