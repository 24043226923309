import { UPDATE_DISPLAY_GROUP, INITIALIZE_DISPLAY_GROUP } from "./actionTypes";

export function updateDisplayGroup(hide, show) {
  return function(dispatch, getState) {
    const { displayGroups } = getState();
    dispatch({
      type: UPDATE_DISPLAY_GROUP,
      displayGroups: displayGroups
        .filter(x => hide.indexOf(x) === -1)
        .concat(show)
    });
  };
}

export function initializeDisplayGroup(displayGroups) {
  return function(dispatch) {
    dispatch({
      type: INITIALIZE_DISPLAY_GROUP,
      displayGroups
    });
  };
}
