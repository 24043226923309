import React from "react";
import PropTypes from "prop-types";
import Static from "../Static";
import handlebars from "handlebars/dist/handlebars";
import * as formatters from "../../../../utils/formatters";

handlebars.registerHelper({
  formatDateTime: (context, options) => {
    return formatters.formatDateTime(context, options.hash.dateFormat);
  },
  formatCurrency: formatters.formatCurrency,
  formatNumber: formatters.formatNumber,
  formatPercentage: formatters.formatPercentage
});

const templateCache = {};

const getTemplate = content => {
  const template = templateCache[content];
  return template || (templateCache[content] = handlebars.compile(content));
};

const Templated = ({ config, record }) => {
  const template = getTemplate(config.content);
  return (
    <Static config={Object.assign({}, config, { content: template(record) })} />
  );
};

Templated.propTypes = {
  config: PropTypes.shape({
    content: PropTypes.string
  }),
  record: PropTypes.object
};

export default Templated;
