import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pill from "../Pill";
import ezMapper from "../../../ezMapper";
import * as filtersActions from "../../../../actions/filtersActions";
import { hasValue } from "../../../../utils/common";

const RangeFilter = ({
  config,
  filter,
  filtersActions,
  displayGroups,
  isLoading
}) => {
  const getValue = () => {
    // if we ever need unicode character support (lt,le,gt,ge,etc), SG has a snippet of code that was written for it.
    if (filter.selectedValues && filter.selectedValues.length > 0) {
      return (config.options && config.options.emptyText) || "Unknown";
    } else if (hasValue(filter.selectedMin)) {
      const min = ezMapper(displayGroups, {
        text: filter.selectedMin,
        config
      });
      const max = ezMapper(displayGroups, {
        text:
          hasValue(filter.selectedMax) && filter.selectedMax > 0
            ? filter.selectedMax
            : filter.max,
        config
      });
      return (
        <span>
          {min}-{max}
        </span>
      );
    } else {
      const max = ezMapper(displayGroups, {
        text: filter.selectedMax,
        config
      });
      return (
        <span>
          {"\u2264"} {max}
        </span>
      );
    }
  };

  const removeFilter = () => {
    filtersActions.filterResults(
      filter.category,
      filter.type,
      null,
      null,
      null
    );
  };

  return (
    <Pill
      label={
        config.options &&
        config.options.enablePillLabel === true &&
        (config.options.title || config.category)
      }
      value={getValue()}
      onClick={removeFilter}
      disabled={isLoading}
    />
  );
};

RangeFilter.propTypes = {
  config: PropTypes.object,
  displayGroups: PropTypes.array,
  filter: PropTypes.object,
  filtersActions: PropTypes.object,
  isLoading: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    isLoading: state.loadingIndicator.filters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RangeFilter);
