import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ezMapper from "../../../ezMapper";
import Pill from "../Pill";
import * as filtersActions from "../../../../actions/filtersActions";
import { hasValue } from "../../../../utils/common";

const TermFilter = ({
  config,
  value,
  filter,
  filtersActions,
  displayGroups,
  isLoading
}) => {
  const removeFilter = () => {
    const selectedValues = filter.selectedValues.filter(x => x !== value);
    filtersActions.filterResults(
      filter.category,
      filter.type,
      selectedValues,
      null,
      null
    );
  };

  return (
    <Pill
      label={
        config.options &&
        config.options.enablePillLabel === true &&
        (config.options.title || config.category)
      }
      value={ezMapper(displayGroups, {
        text: hasValue(value)
          ? value
          : (config.options && config.options.emptyText) || "Unknown",
        config
      })}
      onClick={removeFilter}
      disabled={isLoading}
    />
  );
};

TermFilter.propTypes = {
  config: PropTypes.object,
  displayGroups: PropTypes.array,
  filter: PropTypes.object,
  filtersActions: PropTypes.object,
  isLoading: PropTypes.bool,
  value: PropTypes.string
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    isLoading: state.loadingIndicator.filters
  };
}

function mapDispatchToProps(dispatch) {
  return {
    filtersActions: bindActionCreators(filtersActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermFilter);
