import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as pagingActions from "../../../../actions/pagingActions";
import EzLayout from "../../../EzLayout";
import Button from "@cx/ui/Button";
import ezMapper from "../../../ezMapper";
import objectAssign from "object-assign";

export const LoadMore = ({
  componentData,
  displayGroups,
  paging,
  vehicles,
  isLoading,
  pagingActions
}) => {
  const onClick = () => {
    if (isLoading !== true) {
      pagingActions.loadMoreVehicles();
    }
  };

  const { config } = componentData;

  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        {paging.totalItems > vehicles.length && (
          <Button
            className={`grid-load-more-button ${config.options &&
              config.options.className}`}
            htmlId="grid-load-more-button"
            bsStyle={(config.options && config.options.bsStyle) || "default"}
            size={(config.options && config.options.size) || "default"}
            disabled={isLoading}
            onClick={onClick}
          >
            {config.components && config.components.length > 0 ? (
              config.components.map(component => {
                return ezMapper(
                  displayGroups,
                  objectAssign({}, componentData, { config: component })
                );
              })
            ) : (
              <span>Load More...</span>
            )}
          </Button>
        )}
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

LoadMore.propTypes = {
  componentData: PropTypes.object,
  displayGroups: PropTypes.array,
  isLoading: PropTypes.bool,
  paging: PropTypes.object,
  pagingActions: PropTypes.object,
  vehicles: PropTypes.array
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups,
    isLoading: state.loadingIndicator.inventory,
    paging: state.paging,
    vehicles: state.vehicles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pagingActions: bindActionCreators(pagingActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadMore);
