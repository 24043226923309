import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EzLayout from "../EzLayout";
import Pills from "../reusable/Pills";
import { hasValue } from "../../utils/common";
import Buttons from "../reusable/Buttons";

const EzPill = ({ config, filters, settings }) => {
  const mapPills = () => {
    const pillComponents = [];
    const pills = config.pills || createFilterConfig(settings.filters);
    // we don't know if config pills are real or valid. This means a messy check for each one against the filters
    // tried to use a dictionary here, but it gets too hairy without knowing whats in at least one of the filter lists. Too many moving parts.
    // loop through each config pill
    pills.forEach(pillConfig => {
      // try and match the config pill to a filter from filters in the store
      const filter = matchConfigToFilter(pillConfig.dataIndex);
      // is there a match
      if (hasValue(filter)) {
        // add the filter to the list
        if (["allTerms", "anyTerm", "textSearch"].indexOf(filter.type) > -1) {
          if (filter.selectedValues && filter.selectedValues.length > 0) {
            filter.selectedValues.forEach(value => {
              pillComponents.push(
                <Pills.TermFilter
                  config={pillConfig}
                  filter={filter}
                  value={value}
                />
              );
            });
          }
        } else if (filter.type === "range") {
          if (
            (hasValue(filter.selectedMax) && filter.selectedMax >= 0) ||
            (hasValue(filter.selectedMin) && filter.selectedMin >= 0) ||
            (hasValue(filter.selectedValues) &&
              filter.selectedValues.length > 0)
          ) {
            pillComponents.push(
              <Pills.RangeFilter config={pillConfig} filter={filter} />
            );
          }
        }
      }
    });
    // add clear all button
    if (pillComponents.length > 0) {
      pillComponents.push(<Buttons.ClearFilters />);
    }
    return pillComponents;
  };

  const createFilterConfig = settingsFilters => {
    return settingsFilters.map(x => {
      return {
        type: x.ezPillType,
        options: x.options,
        dataIndex: x.category
      };
    });
  };

  const matchConfigToFilter = dataIndex => {
    // try and find a match
    return filters.find(filter => {
      return filter.category === dataIndex;
    });
  };

  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        {filters.length > 0 && mapPills()}
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

EzPill.propTypes = {
  config: PropTypes.object,
  filters: PropTypes.array,
  settings: PropTypes.object
};

function mapStateToProps(state) {
  return {
    filters: state.filters,
    settings: state.viewConfig.configuration.settings
  };
}

export default connect(
  mapStateToProps,
  null
)(EzPill);
