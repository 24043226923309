import { UPDATE_LOADING_INDICATOR } from "./actionTypes";

export function updateLoadingIndicator(loadingIndicator) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_LOADING_INDICATOR,
      loadingIndicator
    });
  };
}
