export function shouldDisplayShowMore(filter, config = {}) {
  // first, find out how many filters will be displayed
  let filterCount = filter.values.length;
  // if we hide zero counts, remove zero counts from filterCount
  if (config.zeroResults === "hide") {
    const activeFilters =
      filter.values.filter(term => {
        return term.count > 0;
      }) || [];
    filterCount = activeFilters.length;
  }
  // next, find out how many items are displayed in "show less"
  const numRecordsInitiallyVisible = config.showMoreAfter || 3;
  // check everything including whether the show more is even set to show
  return config.showMoreAfter && numRecordsInitiallyVisible < filterCount;
}

export function noChange(selectedValues, initialValues) {
  if (selectedValues.length !== initialValues.length) {
    return false;
  } else {
    selectedValues.forEach(selected => {
      if (initialValues.indexOf(selected) === -1) {
        return false;
      }
    });
  }
  return true;
}
