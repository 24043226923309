import React from "react";
import PropTypes from "prop-types";
import EzLayout from "../EzLayout";
import ezMapper from "../ezMapper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as displayGroupActions from "../../actions/displayGroupActions";
import objectAssign from "object-assign";

const EzMounter = ({ componentData, displayGroups, displayGroupActions }) => {
  const handleOnClick = () => {
    displayGroupActions.updateDisplayGroup(
      componentData.config.options.hide,
      componentData.config.options.show
    );
  };

  return (
    <EzLayout.Section layout={componentData.config.layout}>
      <EzLayout.Wrapper layout={componentData.config.layout}>
        <div className="ezmounter-wrapper" onClick={handleOnClick}>
          {componentData.config.components.map(component => {
            return ezMapper(
              displayGroups,
              objectAssign({}, componentData, { config: component })
            );
          })}
        </div>
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

function mapDispatchToProps(dispatch) {
  return {
    displayGroupActions: bindActionCreators(displayGroupActions, dispatch)
  };
}

EzMounter.propTypes = {
  componentData: PropTypes.object,
  displayGroupActions: PropTypes.object,
  displayGroups: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EzMounter);
