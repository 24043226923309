import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CXTooltip from "@cx/ui/Tooltip";
import EzLayout from "../../EzLayout";
import ezMapper from "../../ezMapper";
import objectAssign from "object-assign";

const Tooltip = ({ componentData, displayGroups }) => {
  const { config } = componentData;
  return (
    <EzLayout.Section layout={config.layout}>
      <EzLayout.Wrapper layout={config.layout}>
        <CXTooltip
          htmlId="use-anywhere-tooltip"
          position={(config.options && config.options.position) || "bottom"}
          tooltipContent={config.content}
        >
          <div className="tooltip-inner-wrapper">
            {config.components.map(component => {
              return ezMapper(
                displayGroups,
                objectAssign({}, componentData, { config: component })
              );
            })}
          </div>
        </CXTooltip>
      </EzLayout.Wrapper>
    </EzLayout.Section>
  );
};

Tooltip.propTypes = {
  componentData: PropTypes.object,
  displayGroups: PropTypes.array
};

function mapStateToProps(state) {
  return {
    displayGroups: state.displayGroups
  };
}

export default connect(
  mapStateToProps,
  null
)(Tooltip);
